import React, { useState } from "react";
import { Link } from "react-router-dom";

interface PhotoUploadProps {
  tab: number;
  settab: React.Dispatch<React.SetStateAction<number>>;
}

interface ImageCardProps {
  idx: number;
  selectedImage: File | null;
  handleImageChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => void;
}

const cardText = [
  { label: "Font View" },
  { label: "Dining area" },
  { label: "Bedroom/Toilet" },
  { label: "Side View" },
  { label: "Rear View" },
  { label: "Berth" },
];

const PhotoUpload: React.FC<PhotoUploadProps> = ({ settab }) => {
  const [selectedImages, setSelectedImages] = useState<(File | null)[]>(
    Array(6).fill(null)
  );

  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const updatedImages = [...selectedImages];
      updatedImages[idx] = file;
      setSelectedImages(updatedImages);
    }
  };

  const ImageCard: React.FC<ImageCardProps> = ({
    idx,
    selectedImage,
    handleImageChange,
  }) => {
    return (
      <label
        htmlFor={`image-input-${idx}`}
        className="flex justify-center items-center cursor-pointer border bg-[#F1F0EF] rounded-2xl h-[331px] relative"
      >
        {selectedImage === null ? (
          <label
            htmlFor={`image-input-${idx}`}
            className="rounded-xl bg-white cursor-pointer py-2 px-4 text-sm lg:text-base font-normal text-secondary hover:bg-primary hover:text-white"
          >
            Add Images
          </label>
        ) : (
          <div className="h-full w-full">
            <img
              className="h-full w-full rounded-xl"
              src={URL.createObjectURL(selectedImage)}
              alt="Selected"
            />
          </div>
        )}
        <input
          id={`image-input-${idx}`}
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e, idx)}
          style={{ display: "none" }}
        />
      </label>
    );
  };
  return (
    <div className={`max-w-[1264px] w-full mx-auto px-4 lg:px-8 mt-6 2xl:mt-12`}>
      <div className="max-w-[1134px] mx-auto w-full">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-y-4 gap-x-3">
          {cardText.map((itm, idx) => (
            <div key={idx}>
              <p className="text-base 2xl:text-lg font-semibold text-secondary mb-3">
                {itm.label}
              </p>
              <ImageCard key={idx} idx={idx} selectedImage={selectedImages[idx]} handleImageChange={handleImageChange} />
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col sm:flex-row items-center gap-2.5 sm:gap-5 mt-6">
        <button
          type="button"
          onClick={() => settab(4)}
          className="text-sm block h-[60px] w-[205px] text-white bg-primary rounded-lg hover:opacity-80"
        >
          Save & continue
        </button>
        <Link
          to=""
          onClick={() => settab(4)}
          className="text-sm 2xl:text-base font-normal text-primary hover:underline underline-offset-2 transition-all"
        >
          Skip Setup
        </Link>
      </div>
    </div>
  );
};

export default PhotoUpload;
