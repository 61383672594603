import React, { useEffect, useState } from "react";
import PageLayout from "../../components/global/PageLayout";
import { MdAccessTimeFilled } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import NewsLetter from "../../components/common/NewsLetter";
import ApiClient from "../../methods/api/apiClient";
import moment from "moment";
import methodModel from "../../methods/methods";
import Breadcrumb from "../../components/common/Breadcrumb";
import { GoClock } from "react-icons/go";
import { IoIosArrowRoundForward } from "react-icons/io";
import CampervanSlider from "../../components/common/CampervanSlider";

const BlogDetail: React.FC = () => {
  const { id } = useParams()
  const [detail, setDetail]: any = useState()
  const [relatedBlogs, setRelatedBlogs] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (id) getBlogDetail()
  }, [])

  const getRelatedBlogs = (categoryId: string) => {
    ApiClient.get(`Blog/all?status=active&category=${categoryId}`).then((res: any) => {
      if (res.success) {
        setRelatedBlogs(res?.data)
      }
    })
  }

  const getBlogDetail = () => {
    ApiClient.get(`Blog?id=${id}`).then((res: any) => {
      if (res.success) {
        setDetail(res?.data)
        getRelatedBlogs(res?.data?.category?.id)
      }
    })
  }

  return (
    <PageLayout>
      <div className="max-w-[1264px] w-full mx-auto px-4 lg:px-8">
        <div className="my-3 lg:my-6 ">
          <Breadcrumb
            links={[
              { link: "/", name: "Home" },
              { link: "/blog", name: "Blog" },
            ]}
            currentPage={detail?.heading}
          />
        </div>
        <h1 className="text-base/5 md:text-xl lg:text-xl 2xl:text-4xl font-semibold text-secondary mb-2.5 lg:mb-5 capitalize">{detail?.heading}</h1>
        <div className="flex gap-3 items-center mb-4 2xl:mb-8">
          {detail?.category?.name ?
            <button
            type="button"
              className="capitalize w-fit shrink-0 py-[9px] px-4 rounded-lg bg-primary/10 flex gap-1 items-center text-sm/4 text-primary hover:opacity-80"
              >
              {detail?.category?.name}
            </button>
            : null}
          <div className="flex items-center gap-2 px-4 py-2">
            <GoClock className="size-5 text-secondary" />
            <p className="text-sm font-normal text-secondary whitespace-nowrap">
              {moment(detail?.createdAt).format('MMMM DD, YYYY')}
            </p>
          </div>
        </div>
        <div className="w-full mb-4 2xl:mb-8">
          <img
            alt="campVan1"
            src={detail?.image ? methodModel.campervanImage(detail?.image) : "/assets/img/camp-van-3.png"}
            className="w-full max-h-[490px] rounded-[20px] object-cover"
          />
        </div>
        <div className="flex items-center justify-center gap-4 2xl:gap-12 flex-wrap lg:flex-nowrap">
          <div className="flex flex-col lg:max-w-[759px] w-full text-base font-normal text-gray-light [&>h1]:text-base [&>h1]:md:text-xl [&>h1]:lg:text-xl [&>h1]:2xl:text-4xl [&>h1]:font-semibold [&>h1]:text-secondary [&>h1]:first:mb-2 [&>h1]:lg:first:mb-3" dangerouslySetInnerHTML={{ __html: detail?.description2 }}></div>
          <div className="lg:max-w-[632px] w-full">
            <img
              alt={detail?.image2_alt}
              src={methodModel.noImg(detail?.image2)}
              className="w-full object-cover rounded-[20px] h-[384px]"
            />
          </div>
        </div>
        <div className="flex items-center flex-col-reverse lg:flex-row justify-center gap-6 2xl:gap-12 flex-wrap lg:flex-nowrap my-6 2xl:my-12">
          <div className="lg:max-w-[632px] w-full">
            <img
              alt={detail?.image3_alt}
              src={methodModel.noImg(detail?.image3)}
              className="w-full object-cover rounded-[20px] h-[384px]"
            />
          </div>
          <div className="flex flex-col lg:max-w-[759px] w-full text-base font-normal text-gray-light [&>h1]:text-base [&>h1]:md:text-xl [&>h1]:lg:text-xl [&>h1]:2xl:text-4xl [&>h1]:font-semibold [&>h1]:text-secondary [&>h1]:first:mb-2 [&>h1]:lg:last:mb-3" dangerouslySetInnerHTML={{ __html: detail?.description3 }}></div>
        </div>
        <div className="w-full mb-4 2xl:mb-8">
          <img
            alt={detail?.image4_alt}
            src={methodModel.campervanImage(detail?.image4)}
            className="w-full rounded-[20px] max-h-[705px] h-full object-cover"
          />
        </div>
        <div className="[&>p]:text-sm [&>p]:md:text-base [&>p]:font-normal [&>p]:text-gray-light mb-6 2xl:mb-12 [&>h1]:md:text-xl [&>h1]:lg:text-xl [&>h1]:2xl:text-4xl [&>h1]:font-semibold [&>h1]:text-secondary [&>h1]:mb-3" dangerouslySetInnerHTML={{ __html: detail?.description4 }}></div>
        <div className="bg-primary rounded-3xl py-5 md:py-8 lg:py-14 img_twos">
          <div className="flex flex-wrap md:flex-nowrap justify-between items-center">
            <div className="textings px-4 md:pl-14 w-full md:w-2/5 text-center md:text-left">
              <h2 className="text-base lg:text-xl 2xl:text-4xl font-semibold text-[#F6F7FB] mb-3 lg:mb-4 max-lg:whitespace-nowrap ">
                Ready to start your journey?
              </h2>
              <p className="text-sm lg:text-base text-[#E3E5ED] mb-4 lg:mb-8">
                Experience our all-inclusive package for a seamless journey from
                start to finish. We take care of everything you need in a
                closed-loop service, ensuring a hassle-free and enjoyable.
              </p>
              <Link className="max-md:mx-auto transition-all px-8 py-3.5 flex items-center justify-center gap-2 h-10 lg:h-[54px] outline-none rounded-xl w-fit text-sm  2xl:text-lg text-[#F6F7FB] font-medium bg-primary border border-[#F6F7FB] hover:opacity-80 " to="/campervans"> 
                Find a Campervan
                <IoIosArrowRoundForward className="size-6 text-white group-hover:text-primary" />
              </Link>
            </div>
            <div className="bgimages flex w-full mt-5 md:m-0 md:w-3/5 h-[280px] lg:h-[350px] xl:h-[410px]">
              <img
                alt={"startJourney"}
                src={"/assets/img/front/Home-Page-img.png"}
                className="mx-auto md:ml-auto max-md:px-4 md:mr-10 w-full max-w-[380px] lg:max-w-[500px] xl:max-w-[610px]"
              />
            </div>
          </div>
        </div>
        <div className="mt-6 2xl:mt-12">
          <h1 className="text-base lg:text-xl 2xl:text-4xl font-semibold text-secondary mb-4 2xl:mb-8">
            Related Blogs
          </h1>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3 ">
            {relatedBlogs && relatedBlogs?.slice(0, 3)?.map((item: any, index: number) => {
              return <div className="w-full flex flex-col gap-6">
                <div className="flex-grow">
                  <div className="w-full relative max-h-[296px] h-full">
                    <img alt="campVan1" src={item?.image ? methodModel.campervanImage(item?.image) : "/assets/img/Rectangle.png"} className="w-full h-full object-cover rounded-lg" />
                    {item?.category_details?.name ?
                      <span className="rounded-lg capitalize text-center min-w-[100px] flex justify-center items-center whitespace-nowrap p-3 h-8 lg:h-9 xl:h-12 bg-secondary text-sm  leading-none lg:font-medium text-white absolute left-5 top-5">
                        {item?.category_details?.name}
                      </span>
                      : null}
                  </div>
                  <div className="mt-4 flex flex-col">
                    <div className="flex items-center gap-2 mb-2.5">
                      <MdAccessTimeFilled className="size-5 text-gray-light" />
                      <p className="text-sm font-normal text-secondary">
                        {moment(item?.createdAt).format('MMMM DD, YYYY')}
                      </p>
                    </div>
                    <h3 className="text-base md:text-lg font-medium text-secondary mb-3 capitalize">{item?.heading || '--'}</h3>
                    <div className="mb-6">
                      <p className="text-ellipsis h-24 text-sm md:text-base font-normal text-secondary/55 break-all line-clamp-4 overflow-hidden" dangerouslySetInnerHTML={{ __html: item?.description2 }}></p>
                    </div>
                  </div>
                </div>
                <div className="mt-16">
                  <button
                    type="button" onClick={e => { navigate(`/blog/detail/${item?._id || item?.id}`) }}
                    className="w-[162px] h-11 mt-12 shrink-0 py-3 rounded-xl bg-transparent border border-primary text-base md:text-lg/5 font-normal text-primary hover:bg-primary hover:text-white transition-all">
                    Read More
                  </button>
                </div>
              </div>
            })}
          </div>
        </div>
        {/* <div className="mt-6 lg:mt-28">
          <NewsLetter />
        </div> */}
      </div>
      <div className="bg-[#F6F7FB] py-6 2xl:py-12 border-b border-gray-border mt-6 2xl:mt-12">
        <div className="max-w-[1264px] w-full mx-auto px-4 lg:px-8">
          <h2 className="font-medium text-secondary text-base lg:text-xl  2xl:text-[32px] mb-4 2xl:mb-8">Discover your best Campervan</h2>
          <CampervanSlider />
        </div>
      </div>
    </PageLayout>
  );
};

export default BlogDetail;
