import Layout from '../../components/global/layout'

const MyRewards = () => {
  return (
    <Layout>
      <div className="w-full border border-gray-border rounded-2xl p-4 2xl:p-6">
        <h2 className="text-base lg:text-xl 2xl:text-2xl text-secondary font-semibold mb-5">My Rewards</h2>
        <div className="scrollbar !overflow-x-auto ">
          <table className="w-full text-left">
            <thead className='bg-[#F6F7FB]'>
              <tr className='h-10'>
                <th className="text-sm font-medium text-secondary whitespace-nowrap px-6 py-2.5">Date</th>
                <th className="text-sm font-medium text-secondary whitespace-nowrap px-6 py-2.5 ">Invoice ID</th>
                <th className="text-sm font-medium text-secondary whitespace-nowrap px-6 py-2.5">Amount</th>
                <th className="text-sm font-medium text-secondary whitespace-nowrap px-6 py-2.5">Points Earned or Redeemed</th>
                <th className="text-sm font-medium text-secondary whitespace-nowrap px-6 py-2.5">Balance</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(2)].map((_itm, idx) => (
                <tr
                  key={idx}
                  className="border-gray-border border-b text-sm 2xl:text-base font-normal text-gray-light whitespace-nowrap">
                  <td className="p-4 text-left">12-04-2024</td>
                  <td className='p-4'>12-04-2024</td>
                  <td className='p-4'>$1400</td>
                  <td className= {`p-4 ${idx === 1 ? 'text-[#E13C29] ' : ' text-primary'} `}>
                    {idx === 1 ? '-1500' : '+1500'}
                  </td>
                  <td className='p-4'>1400</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default MyRewards