import { useEffect, useState } from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../sidebar';
import Header from '../header';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';


const Layout = ({ children }) => {
  const user = useSelector((state)=> state.User)
  const history = useNavigate();
  const [isOpen, setIsopen] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history('/login');
    }
  }, [])

  const logo = () => {
    let value = '/assets/img/logo.png'
    return value
  }

  const logos = () => {
    let value = '/assets/img/logo-small.png'
    return value
  }

  const router = () => {
    let route = localStorage.getItem('route')
    history(route);
  }

  const [state, setstate] = useState(false)
  useEffect(() => {
    setstate(localStorage.getItem("sidebar"))
  }, [localStorage.getItem("sidebar")])

  return (
    <>
      <div component="layout">
        <div onClick={e => router()} id="routerDiv"></div>
        <Header isOpen={isOpen} setIsOpen={setIsopen} />

        {/* <div className={`main-wrapper flex ${isOpen ? "active-sidebar" : ""
          }`}>

          <div className="main-sidebar scrollbar transition-[width] duration-300">
            <div className="sidebar-brand p-3 pt-4 pl-5 text-left">
                <Link to="/">
              <div className="editLogo">
                <img src={logo()} width="250" height="35" className="pl-3 show-logo" />
                <img src={logos()} className="hide-logo" />
              </div>
              </Link>
            </div>
            {user?.logo ? (
              <div
                className="flex justify-center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <img
                  src={methodModel.userImg(user?.logo || "")}
                  alt="photo"
                  width="40"
                  height="40"
                  style={{
                    width: "40px",
                    marginBottom: "2px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                />
              </div>
            ) : null}
            <Sidebar isOpen={isOpen} />
          </div>



          <div className="main-sidebar  d-md-block">

            <div className="sidebar-brand p-3 pt-4  text-left pl-5">
              <label className='editLogo'>
                <img src={logo()} className="logocls show-logo" />
                <img src={logos()} className=" hide-logo" />
              </label>

            </div>
            <Sidebar />
          </div>
          <main className="main">
            <div className="mainarea">{children}</div>
          </main>
        </div> */}

        <div className='max-w-[1264px] w-full mx-auto px-4 lg:px-8 mt-4 2xl:mt-8'>
            <div className='main_agents grid grid-cols-12 gap-3 2xl:gap-6 '>
           
              <div className='col-span-12 md:col-span-4 lg:col-span-4 xl:col-span-3'>
                  <div className='agent-sides pb-4'> 
                    <Sidebar isOpen={isOpen} />
                  </div>

              </div>
              <div className='col-span-12 md:col-span-8 lg:col-span-8  xl:col-span-9 pb-5 md:pb-10'>
                <div className='agent_showings bg-white' > 
                    <div className="">{children}</div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
export default Layout;
