import PhoneInput from "react-phone-input-2";
import methodModel from "../../../methods/methods";
import { MdDelete } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import Select from 'react-select';
import { colourStyles } from "../../../utils/selectStyle";

const Html = ({
  handleSubmit,
  setForm,
  form,
  ImageUpload,
  navigate,
  nameValidation,
  cities
}) => {


  return (
    <>
      <div className="wrapper_section pb-10">
        <form name="profileForm" autoComplete="off" onSubmit={handleSubmit}>
          <div className="border border-gray-border p-3 2xl:p-6 rounded-2xl">
            <h3 className="text-xl font-semibold text-secondary sm:text-left text-center mb-3 2xl:mb-5"> Profile{" "}</h3>
            <div className="grid sm:grid-cols-2 gap-3">
            <div className="flex justify-between  cursor-pointer gap-3 lg:gap-4 2xlgap-6 max-sm:mx-auto">
              {form?.image ? (
                <>
                  {" "}
                  <img src={methodModel.userImg(form?.image)} className="rounded-full h-10 w-10 block" />
                  <div className="">
                    <button
                      className="text-gray-light gap-2  border border-gray-border flex whitespace-nowrap h-[41px] max-w-24 w-full items-center justify-center text-base font-medium rounded-xl px-3 py-1.5 hover:border-transparent hover:bg-primary hover:text-[#F6F7FB]"
                      onClick={(e) => setForm({ ...form, image: "" })}
                      type="button"
                    >
                      {/* <MdDelete className="size-3" />  */}
                      Delete
                    </button>
                  </div>
                </>
              ) : (
                <label htmlFor="dropzone-file" className=" flex  items-start justify-center  rounded-xl cursor-pointer w-full">
                  <div className="flex flex-row items-center justify-between w-full gap-5">
                    <img
                      className=" h-10 w-10 object-cover rounded-full block"
                      src={methodModel.userImg(form?.image)}
                    />
                    <div className="text-primary gap-2  border border-gray-border flex whitespace-nowrap h-[41px] max-w-[100px] w-full items-center justify-center text-base font-medium rounded-xl px-3 py-1.5 hover:border-transparent hover:bg-primary hover:text-[#F6F7FB]">
                      {/* <FiUpload className="text-white size-3" /> */}
                      Upload
                    </div>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={ImageUpload}
                  />
                </label>
              )}
            </div>
            
            <div className="w-full" id="citySelect">
                <label className="text-sm/4 font-medium block text-secondary mb-1 lg:mb-2">
                  City 
                  {/* <span className="text-red-600">*</span> */}
                </label>
                <Select
                  styles={colourStyles}
                  placeholder="Select your city"
                  className="w-full !rounded-xl cursor-pointer !border-gray-border capitalize"
                  value={form?.city}
                  onChange={(e) => setForm({ ...form, city: e })}
                  options={cities}
                  isClearable
                  required
                  classNamePrefix="react"
                />
              </div>
              
              <div className="">
                <label className="text-sm/4 font-medium block text-secondary mb-1 lg:mb-2">
                  First Name & Last Name 
                  {/* <span className="text-red-600">*</span> */}
                </label>
                <input
                  type="text"
                  value={form?.fullName}
                  onChange={(e) => nameValidation(e, "fullName")}
                  placeholder="First Name & Last Name"
                  className="border-0 p-4 relative shadow-box bg-white w-full rounded-xl h-10 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none appearance-none"
                  required
                />
              </div>
              <div className="w-full">
                <label className="text-sm/4 font-medium block text-secondary mb-1 lg:mb-2">
                Phone Number 
                  {/* <span className="text-red-600">*</span> */}
                </label>
                <div className="mobile_input" id="profileEditNumber">
                  <PhoneInput
                    country={"au"}
                    value={form?.mobileNo}
                    enableSearch={true}
                    inputClass={
                      " !border-0 relative shadow-box bg-white !w-full !h-10 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none appearance-none "
                    }
                    dropdownClass={"!w-fit max-sm:!mt-12"}
                    inputProps={{ required: true }}
                    containerClass={"!w-full"}
                    onChange={(e) => setForm({ ...form, mobileNo: e })}
                    countryCodeEditable={true}
                  />
                </div>
              </div>
              <div className="">
                <label className="text-sm/4 font-medium block text-secondary mb-1 lg:mb-2">
                Email Address
                  {/* <span className="text-red-600">*</span> */}
                </label>
                <input
                  type="email"
                  className="disabled:cursor-not-allowed  border-0 p-4 relative shadow-box bg-white w-full rounded-xl h-10 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none appearance-none"
                  placeholder="Email"
                  value={form?.email}
                  onChange={(e) => {
                    setForm({ ...form, email: e.target.value });
                  }}
                  required
                  disabled
                />
              </div>
              
              
              <div className="w-full">
                <label className="text-sm/4 font-medium block text-secondary mb-1 lg:mb-2">
                  Date Of Birth
                   {/* <span className="text-red-600">*</span> */}
                </label>
                <input
                  type="date"
                  value={form?.dateOfBirth}
                  onChange={e=>setForm({ ...form, dateOfBirth: e.target.value })}
                  max={new Date().toISOString().split('T')[0]}
                  className="disabled:cursor-not-allowed  border-0 p-4 relative shadow-box bg-white w-full rounded-xl h-10 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none appearance-none"
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 mt-5 flex-wrap justify-end">
            <button onClick={(e) => { navigate(-1) }} type="button" className="w-[100px] shrink-0 h-10 xl:h-12 2xl:h-[54px] border border-gray-border hover:bg-primary hover:border-transparent hover:text-[#F6F7FB] text-gray-light transition-all text-lg font-medium rounded-xl flex justify-center items-center">Cancel</button>
            <button type="submit" disabled={form?.mobileNo?.length <= 8} className="cursor-pointer w-[150px] shrink-0 h-10 xl:h-12 2xl:h-[54px] hover:opacity-80 text-[#F6F7FB] transition-all rounded-xl flex justify-center items-center bg-primary text-lg font-medium">Save Changes</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Html;
