import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineCheck } from "react-icons/md";
import { FiCheck } from "react-icons/fi";
import { bathroomItems, drivingItems, featureItems, kitchenItems, roadItems } from "../../utils/static-data";

interface AmenitiesProps {
  tab: number;
  settab: React.Dispatch<React.SetStateAction<number>>;
}



interface SelectedItemsState {
  [key: string]: string[];
}

const Amenities: React.FC<AmenitiesProps> = ({ settab }) => {

  const [selectedItems, setSelectedItems] = useState<SelectedItemsState>({
    kitchenItems: [],
    bathroomItems: [],
    drivingItems: [],
    roadItems: [],
    featureItems: [],
  });

  const handleItemClick = (label: string, category: string) => {
    setSelectedItems((prevState) => ({
      ...prevState,
      [category]: prevState[category].includes(label)
        ? prevState[category].filter((item) => item !== label)
        : [...prevState[category], label],
    }));
  };

  return (
    <div className={`max-w-[1264px] w-full mx-auto px-4 lg:px-8 mt-6 2xl:mt-12`}>
      <h3 className="text-xl 2xl:text-2xl/9 font-semibold text-secondary mb-4">
        Required number of features: 6 Facilities and Features
      </h3>
      <div className="flex justify-between flex-wrap flex-col sm:flex-row gap-4">
        <div className="w-auto">
          <p className="text-lg font-semibold text-secondary mb-3">Kitchen</p>
          <div className="grid grid-cols-3 gap-3 mx-auto sm:mx-0">
            {kitchenItems.map((itm, idx) => (
              <div className="w-[133px] h-[132px]">
                <div
                  onClick={() => handleItemClick(itm.label, "kitchenItems")}
                  key={idx}
                  className={`cursor-pointer w-full relative h-full rounded-xl p-[3px] mb-1 border ${selectedItems.kitchenItems.includes(itm.label)
                    ? "border-primary"
                    : "border-gray-border"
                    } `}
                >
                  <p
                    className={`absolute size-5 right-1 rounded-full flex items-center justify-center  ${selectedItems.kitchenItems.includes(itm.label)
                      ? "bg-primary"
                      : "bg-[#CDCDCD]"
                      } `}
                  >
                    <FiCheck 
                    className={`size-3 font-medium  ${selectedItems.kitchenItems.includes(itm.label)
                      ? "text-white"
                      : "text-secondary"
                      } `}/>
                    {/* <MdOutlineCheck
                      className={`size-3 font-medium ${selectedItems.kitchenItems.includes(itm.label)
                        ? "text-white"
                        : "text-secondary"
                        } `}
                    /> */}
                  </p>
                  <div className="flex flex-col items-center">
                    <div className="bg-gray-border w-full h-[104px] rounded-[10px] mb-1" />
                    <p className="text-sm font-normal text-secondary line-clamp-1 text-center">
                      {itm.label}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-auto">
          <p className="text-lg font-semibold text-secondary mb-3">Bathroom</p>
          <div className="grid grid-cols-3 gap-3 mx-auto sm:mx-0">
            {bathroomItems.map((itm, idx) => (
              <div
                onClick={() => handleItemClick(itm.label, "bathroomItems")}
                key={idx}
                className={`cursor-pointer w-[133px] relative h-[132px] rounded-xl p-[3px] mb-1 border ${selectedItems.bathroomItems.includes(itm.label)
                  ? "border-primary"
                  : "border-gray-border"
                  } `}
              >
                <p
                  className={`absolute size-5 right-1 rounded-full flex items-center justify-center ${selectedItems.bathroomItems.includes(itm.label)
                    ? "bg-primary"
                    : "bg-[#CDCDCD]"
                    } `}
                >
                  <FiCheck 
                    className={`size-3 font-medium  ${selectedItems.bathroomItems.includes(itm.label)
                      ? "text-white"
                      : "text-secondary"
                      } `}/>
                  {/* <MdOutlineCheck
                    className={`size-3 font-medium ${selectedItems.bathroomItems.includes(itm.label)
                      ? "text-white"
                      : "text-secondary"
                      } `}
                  /> */}
                </p>
                <div className="flex flex-col items-center">
                  <div className="bg-gray-border w-full h-[104px] rounded-[10px] mb-1" />
                  <p className="text-sm font-normal text-secondary line-clamp-1 text-center">
                    {itm.label}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-auto">
          <p className="text-lg font-semibold text-secondary mb-3">Driving Safety</p>
          <div className="grid grid-cols-3 gap-3 mx-auto sm:mx-0">
            {drivingItems.map((itm, idx) => (
              <div
                onClick={() => handleItemClick(itm.label, "drivingItems")}
                key={idx}
                className={`cursor-pointer w-[133px] relative h-[132px] rounded-xl p-[3px] mb-1 border ${selectedItems.drivingItems.includes(itm.label)
                  ? "border-primary"
                  : "border-gray-border"
                  } `}
              >
                <p
                  className={`absolute size-5 right-1 rounded-full flex items-center justify-center ${selectedItems.drivingItems.includes(itm.label)
                    ? "bg-primary"
                    : "bg-[#CDCDCD]"
                    } `}
                >
                  <FiCheck 
                    className={`size-3 font-medium  ${selectedItems.drivingItems.includes(itm.label)
                      ? "text-white"
                      : "text-secondary"
                      } `}/>
                  {/* <MdOutlineCheck
                    className={`size-3 font-medium ${selectedItems.drivingItems.includes(itm.label)
                      ? "text-white"
                      : "text-secondary"
                      } `}
                  /> */}
                </p>
                <div className="flex flex-col items-center">
                  <div className="bg-gray-border w-full h-[104px] rounded-[10px] mb-1" />
                  <p className="text-sm font-normal text-secondary line-clamp-1 text-center">
                    {itm.label}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-between flex-col sm:flex-row gap-4 mt-6 lg:mt-12">
        <div className="w-auto">
          <p className="text-lg font-semibold text-secondary mb-3">Living on the road</p>
          <div className="grid grid-cols-3 gap-3 mx-auto sm:mx-0">
            {roadItems.map((itm, idx) => (
              <div
                onClick={() => handleItemClick(itm.label, "roadItems")}
                key={idx}
                className={`cursor-pointer w-[133px] relative h-[132px] rounded-xl p-[3px] mb-1 border ${selectedItems.roadItems.includes(itm.label)
                  ? "border-primary"
                  : "border-gray-border"
                  } `}
              >
                <p
                  className={`absolute size-5 right-1 rounded-full flex items-center justify-center ${selectedItems.roadItems.includes(itm.label)
                    ? "bg-primary"
                    : "bg-[#CDCDCD]"
                    } `}
                >
                  {/* <MdOutlineCheck
                    className={`size-3 font-medium ${selectedItems.roadItems.includes(itm.label)
                      ? "text-white"
                      : "text-secondary"
                      } `}
                  /> */}
                  <FiCheck 
                    className={`size-3 font-medium  ${selectedItems.roadItems.includes(itm.label)
                      ? "text-white"
                      : "text-secondary"
                      } `}/>
                </p>
                <div className="flex flex-col items-center">
                  <div className="bg-gray-border w-full h-[104px] rounded-[10px] mb-1" />
                  <p className="text-sm font-normal text-secondary line-clamp-1 text-center">
                    {itm.label}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-auto">
          <p className="text-lg font-semibold text-secondary mb-3">Other features</p>
          <div className="grid grid-cols-3 gap-3 mx-auto sm:mx-0">
            {featureItems.map((itm, idx) => (
              <div
                onClick={() => handleItemClick(itm.label, "featureItems")}
                key={idx}
                className={`cursor-pointer w-[133px] relative h-[132px] rounded-xl p-[3px] mb-1 border ${selectedItems.featureItems.includes(itm.label)
                  ? "border-primary"
                  : "border-gray-border"
                  } `}
              >
                <p
                  className={`absolute size-5 right-1 rounded-full flex items-center justify-center ${selectedItems.featureItems.includes(itm.label)
                    ? "bg-primary"
                    : "bg-[#CDCDCD]"
                    } `}
                >
                  {/* <MdOutlineCheck
                    className={`size-3 font-medium ${selectedItems.featureItems.includes(itm.label)
                      ? "text-white"
                      : "text-secondary"
                      } `}
                  /> */}
                  <FiCheck 
                    className={`size-3 font-medium  ${selectedItems.featureItems.includes(itm.label)
                      ? "text-white"
                      : "text-secondary"
                      } `}/>
                </p>
                <div className="flex flex-col items-center">
                  <div className="bg-gray-border w-full h-[104px] rounded-[10px] mb-1" />
                  <p className="text-sm font-normal text-secondary line-clamp-1 text-center">
                    {itm.label}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="hidden xl:block w-[318px]" />
      </div>
      <div className="flex flex-col sm:flex-row items-center gap-3 mt-8 sm:gap-5 mb-12">
        <button
          type="button"
          onClick={() => settab(5)}
          className="text-sm block h-[60px] w-[205px] text-white bg-primary rounded-lg hover:opacity-80"
        >
          Save & continue
        </button>
        <Link
          to=""
          onClick={() => settab(5)}
          className="text-sm lg:text-base 2xl:text-lg/5 font-medium text-gray-light flex items-center justify-center h-10 xl:h-12 2xl:h-[54px] w-full sm:max-w-[152px] rounded-xl border border-gray-border hover:bg-primary hover:text-[#F6F7FB] transition-all"
        >
          Skip Setup
        </Link>
      </div>
    </div>
  );
};

export default Amenities;
