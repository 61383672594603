import React from 'react'
import MessageTabs from '../../components/common/Tabs'
import Header from '../../components/global/header'

const MessageInbox: React.FC = () => {
	return (
		<>
			<Header />
			<div className="max-w-[1264px] w-full mx-auto px-4 lg:px-8 pt-8 messageupdate">
				<h3 className="text-[26px] font-semibold text-secondary mb-10">
					Inbox
				</h3>
				<div className="">
					<MessageTabs />
				</div>
			</div>

		</>
	)
}

export default MessageInbox