import { Link } from "react-router-dom"
import { ReviewData } from "../../../utils/static-data"
import ReviewCard from "./ReviewCard"
import { HomeData } from "../../../Pages/Home"
import { useContext } from "react"
import { IoIosArrowRoundForward } from "react-icons/io";

const AboutUs = () => {
  const homedata:any = useContext(HomeData) // Get data using useContext from Home.tsx

  return (
    <>
      <h2 className="font-semibold text-secondary text-center text-base/5 md:text-xl lg:text-xl 2xl:text-4xl">
        {homedata?.part3_heading1?homedata?.part3_heading1:"What real people are saying about us"}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-none lg:grid-flow-col lg:grid-rows-2 gap-3 lg:gap-4 2xl:gap-8 my-4 2xl:my-8">
        {ReviewData.map((itm, idx) => (
          <div key={idx} className={`lg:col-span-1 ${(idx == 1 || idx == 2) ? "lg:row-span-1" : "lg:row-span-2"}`}>
            <ReviewCard itm={itm} />
          </div>
        ))}
      </div>
      <Link to="#" className="px-5 py-2 lg:py-3 transition-all rounded-xl text-sm 2xl:text-base text-[#F6F7FB] bg-primary hover:opacity-80 w-fit mx-auto flex justify-start items-center gap-2">{homedata?.seeAllStoriesButton?homedata?.seeAllStoriesButton:"See All Stories"}
      <IoIosArrowRoundForward className="size-5 text-white" />
      </Link>
    </>
  )
}

export default AboutUs