import { useEffect, useState } from 'react';
import PageLayout from '../../components/global/PageLayout';
import ApiClient from '../../methods/api/apiClient';
import Breadcrumb from '../../components/common/Breadcrumb';

const CookiesPolicy = () => {
    const [detail, setdetail] = useState()

    useEffect(() => {
        ApiClient.get(`content?title=cookie policy`).then((res) => {
            if (res.success) {
                setdetail({
                    meta_title: res?.data?.meta_title,
                    meta_description: res?.data?.meta_description,
                    meta_key: res?.data?.meta_key,
                    description: res?.data?.description,
                })
            }
        });
    }, [])

    const details = {
        campervan_name: "Cookies Policy",
    };

    return (
        <>
            <PageLayout>
                <div className='max-w-[1264px] w-full mx-auto px-4 lg:px-8'>
                    <div className="lg:my-6 my-3">
                        <Breadcrumb
                            links={[
                                { link: "/", name: "Home" },
                            ]}
                            currentPage={details?.campervan_name}
                        />
                    </div>
                    <p className='[&>h3]:text-base  [&>h3]:lg:text-xl  [&>h3]:2xl:text-2xl [&>h3]:text-secondary [&>h3]:mb-3 [&>h3]:font-semibold [&>p]:text-gray-light [&>p]:font-normal [&>h1]:text-base [&>h1]:md:text-xl [&>h1]:lg:text-2xl [&>h1]:2xl:text-4xl [&>h1]:font-semibold [&>h1]:text-secondary [&>ul]:list-disc [&>ul]:pl-7 [&>ul]:text-gray-light mb-6 2xl:mb-12' dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                </div>
            </PageLayout>
        </>
    );
};

export default CookiesPolicy;