import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ApiClient from "../../methods/api/apiClient";
import InputSelect from "../../components/common/InputSelect";

interface DescriptionRuleProps {
  tab: number;
  settab: React.Dispatch<React.SetStateAction<number>>;
}

const DescriptionRule: React.FC<DescriptionRuleProps> = ({ settab }) => {
  const [form, setForm] = useState({ email: "" });
  const [emailSent, setemailSent] = useState(false);

  const FormSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = (value: any) => {
    let payload: any = {
      email: value.email,
    };
    ApiClient.post("", payload).then((res) => {
      if (res.success) {
        setForm(value);
        setemailSent(true);
      } else {
        setemailSent(false);
      }
    });
  };

  const license = [
    { name: "License 1" },
    { name: "License 2" },
    { name: "License 3" },
  ];
  const [selectedLicense, setSelectedLicense] = useState(license[0]);

  return (
    <div className={`max-w-[1264px] w-full mx-auto px-3 lg:px-8 mt-6 2xl:mt-12`}>
      <Formik
        initialValues={form}
        validationSchema={FormSchema}
        onSubmit={handleSubmit}
      >
        <Form className="">
          <div className="max-w-[878px] w-full mb-6 2xl:mb-12">
            <label className="text-sm 2xl:text-lg/7 text-secondary font-semibold block mb-3">
              Listing Name
            </label>
            <Field
              type="text"
              name="text"
              className=" focus:ring-offset-[1.2px] ring-1 ring-[#CDCDCD] focus:ring-primary outline-none  !border-0  rounded-lg w-full !h-[47px] p-4 block mb-4  appearance-none"
            />
            <label className="text-sm 2xl:text-lg/7 text-secondary font-semibold block mb-3">
              Vehicle Description
            </label>
            <textarea
              name=""
              id=""
              placeholder="Write a compelling description about your RV here!"
              className="resize-none placeholder:text-gray-light text-sm/5 font-normal  text-secondary w-full h-[96px] focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl  !border-0  px-4 py-3  block appearance-none"
            ></textarea>
          </div>
          <h6 className="text-base font-normal text-secondary mb-3">Indie tip</h6>
          <p className="text-base font-normal text-secondary/70 max-w-[1012px] mb-6 2xl:mb-12">
            Use this space to write a few sentences about your vehicle, about what
            the experiences of traveling in it is like anad how it can be the best
            booking option for the traveler. if you ned any support, don’t hesitate
            and contact us.
          </p>
          <h3 className= "text-lg 2xl:text-2xl font-bold text-secondary mb-3">Listing Rules</h3>
          <div className="grid sm:grid-cols-2 gap-3 mb-6">
            <div className="">
              <label
                htmlFor=""
                className="text-sm 2xl:text-lg font-semibold text-secondary block mb-3">
                Minimum age of driver
              </label>
              <div className="flex">
                <Field
                  type="text"
                  name="text"
                  className="resize-none focus:ring-offset-[1.2px] ring-1 ring-[#CDCDCD] focus:ring-primary outline-none rounded-lg !border-0  w-full !h-[47px] p-4 block appearance-none"
                />
                {/* <span className="px-6 py-[18px] rounded-r-lg bg-[#E6E6E6] text-base font-normal text-secondary -my-[.9px]">
                  Years
                </span> */}
              </div>
            </div>
            <div className="">
              <label
                htmlFor=""
                className="text-sm 2xl:text-lg font-semibold text-secondary block mb-3"
              >
                Required driving licence
              </label>
              <InputSelect
                classes={"w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none "}
                labelBg={"bg-white"}
                options={license}
                value={selectedLicense}
                onChange={setSelectedLicense}
              />
            </div>
          </div>
        </Form>
      </Formik>
      <div className="flex flex-col sm:flex-row items-center gap-3 mt-8 sm:gap-5 mb-12">
        <button
          type="button"
          onClick={() => settab(6)}
          className=" text-sm block h-[60px] w-[205px] text-white bg-primary rounded-lg hover:opacity-80"
        >
          Save & continue
        </button>
        <Link
          to=""
          onClick={() => settab(6)}
          className="text-sm lg:text-base 2xl:text-lg/5 font-medium text-gray-light flex items-center justify-center h-10 xl:h-12 2xl:h-[54px] w-full sm:max-w-[152px] rounded-xl border border-gray-border hover:bg-primary hover:text-[#F6F7FB] transition-all"
        >
          Skip Setup
        </Link>
      </div>
    </div>
  );
};

export default DescriptionRule;
