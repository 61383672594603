import PageLayout from "../components/global/PageLayout";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import NewsLetter from "../components/common/NewsLetter";
import AboutUs from "../components/common/AboutUs";
import TrendingDestinationSlider from "../components/common/TrendingDestinationSlider";
import CampervanSlider from "../components/common/CampervanSlider";
import SearchBanner, { searchFilterIF } from "../components/common/SearchBanner";
import { createContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { SEARCH_STATE } from "../Redux/Action/Action";
import OurServices from "../components/common/OurServices";
import { useSelector } from "react-redux";
import ApiClient from "../methods/api/apiClient";
import methodModel from "../methods/methods";
import { IoIosArrowRoundForward } from "react-icons/io";
import { FiChevronDown } from "react-icons/fi";

export const HomeData = createContext({}) // Create Context for Home page data change and pass to child components

const Home = () => {
    const handleScroll = () => {
      window.scrollBy({ top: 730, behavior: 'smooth' });
    };
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [active, setActive] = useState(0)
  const search = useSelector((state: any) => state.Reducer.search)
  const [cities, setCities] = useState([])
  const [homedata, sethomedata] = useState({ part2_heading1: "", part2_heading2: "", part2_heading3: "", part2_description4: "", part2_description5: "", part2_heading4: "", part2_description6: "", part2_button4: "", part2_heading5: "", part3_heading1: "", part3_heading2: "", part3_button: "", part3_heading3: "", bannerBackgroundImage: "", part2_icon1: "", part2_icon2: "", part2_icon3: "", part2_journey_image: "", part2_journey2_image: "", part3_list_background_image: "" })
  const [searchFilter, setSearchFilter] = useState<searchFilterIF>({
    peopleCount: 0,
    startDate: new Date().toISOString().substring(0, 10),
    endDate: new Date().toISOString().substring(0, 10)
  })
  const pickUpRef = useRef()

  useEffect(() => {
    getCities()
    getdetail()
  }, [])

  const getCities = () => {
    ApiClient.get(`cities?status=active&isPopulated=true`).then(res => {
      if (res.success) {
        setCities(res?.data?.map((item: any) => {
          return ({ ...item, value: item?.id || item?._id, label: item?.city_name })
        }))
      }
    })
  }
  const getdetail = () => {
    ApiClient.get(`HomePage?slug=home_page`).then(res => {
      if (res?.success) {
        sethomedata(res?.Data)
      }
    })
  }

  // This function is used to handle search campervan filters with the help of redux persist
  const handleSearchCampervans = (e: any) => {
    let fields = {
      dropOffLocation: e?.dropOffLocation || '',
      end_date: e?.end_date || '',
      isDriverAge: e?.isDriverAge || '',
      pickUpLocation: e?.pickUpLocation || '',
      start_date: e?.start_date || '',
      type: search?.type || '',
      isReturn: e?.isReturn || '',
      region: e?.region || '',
      activeIdx: active,
      campervan_type: "",
      ageid: e?.ageid,
    }
    dispatch(SEARCH_STATE({ ...search, ...fields }))
    navigate('/campervans')
  }

  return (
    <>
      {/* Wrap components using useContext and pass homedata state as value */}
      <HomeData.Provider value={homedata}>
        <PageLayout>
          <div >
            {/* <h1 className="font-semibold text-secondary text-lg/5 sm:text-[26px]/6 lg:text-3xl lg:text-[40px] mb-6 ">
              Discover your ideal RV adventure
            </h1> */}
            <SearchBanner
              searchFilter={searchFilter}
              listingPage
              setSearchFilter={setSearchFilter}
              activeIdx={active}
              setActiveIdx={setActive}
              bgImage={homedata?.bannerBackgroundImage?methodModel.noImg(homedata?.bannerBackgroundImage):"assets/img/banner-img.png"}
              handleSearchCampervans={handleSearchCampervans}
              pickUpRef={pickUpRef}
            />
          </div>     
          <div className="w-full mx-auto ">
          <div className="-mt-[14px]">
            <button className="flex justify-center items-center  rounded-full w-9 h-9 mx-auto border border-gray-border hover:border-transparent bg-[#F6F7FB] hover:bg-primary group transition-all animate-bounce"
             onClick={handleScroll}>
          <FiChevronDown size={30} className="group-hover:text-white" />
          </button>
          </div>
            
            <div className="mt-2 lg:mt-4 2xl:mt-8 mb-4 lg:mb-6 2xl:mb-12 bg-white max-w-[1264px] w-full mx-auto px-4 lg:px-8">
              <div className="titled-haed mx-auto">
                <h2 className="font-semibold text-secondary text-lg lg:text-xl 2xl:text-4xl mb-2 text-center w-full max-w-[680px] mx-auto">
                  {homedata?.part2_heading1 ? homedata?.part2_heading1 : "No 1 road trip provider in North America, Oceania and Europe"}
                </h2>
              </div>
              <OurServices detail={[]} />
            </div>
            <div className="bg-[#F6F7FB]">
            <div className="py-7 2xl:py-14 max-w-[1264px] w-full mx-auto px-4 lg:px-6 2xl:px-8">
              <h2 className="font-semibold text-secondary text-[15px] sm:text-xl lg:text-xl 2xl:text-4xl mb-4 lg:mb-5 2xl:mb-8">
                {homedata?.part2_heading2 ? homedata?.part2_heading2 : "Discover your best Campervan"}
              </h2>
              <CampervanSlider />
            </div>
            </div>
            <div className="max-md:mt-3 lg:mt-6 2xl:mt-12 overflow-hidden">
              <div className="flex items-center flex-col md:flex-row flex-wrap gap-6 2xl:gap-x-12 h-full max-w-[1264px] mx-auto px-4 lg:px-8">
                <div className="imgsilme w-full lg:max-w-[500px]  xl:max-w-[705px]">
                  <div className="relative">
                    <img className="w-full top-0 max-sm:h-auto max-md:h-full max-lg:h-[600px] lg:h-[500px] 2xl:h-[555px] max-lg:object-fit rounded-3xl" alt="smilegril" src={homedata?.part2_journey_image?methodModel.noImg(homedata?.part2_journey_image):"assets/img/front/smilegril.png"} />
                  </div>
                </div>
                <div className="imgsilme w-full lg:flex-1">
                  <div className="">
                    <h6 className="2xl:max-w-[407px] font-semibold text-secondary text-base md:text-xl/5  lg:text-xl 2xl:text-4xl">
                      {homedata?.part2_heading3 ? homedata?.part2_heading3 : "End-to-end service your complete journey"}
                    </h6>
                    <p className="text-sm lg:text-base text-gray-light mt-2 lg:mt-3 font-normal">{homedata?.part2_description4 ? homedata?.part2_description4 : "Experience our all-inclusive package for a seamless journey from start to finish. We take care of everything you need in a closed-loop service, ensuring a hassle-free and enjoyable experience from booking to return.  Sit back, relax, and let us handle every detail for you."}</p>
                  </div>
                  <div className="w-full  lg:max-w-[415px] bg-[#F6F7FB] p-3 lg:p-4 2xl:p-8 mt-3 lg:mt-4 2xl:mt-8 rounded-[20px] show-order max-sm:text-sm text-base [&>ol]:pl-4 [&>ol]:list-disc [&>ul]:list-disc [&>ol>li]:marker:text-primary [&>ol>li]:mb-3 lg:[&>ol>li]:mb-3 2xl:[&>ol>li]:mb-5" dangerouslySetInnerHTML={{ __html: homedata?.part2_description5 }}></div>
                </div>
              </div>
            </div>
            <div className="mt-4 lg:mt-6 2xl:mt-12 pb-4 lg:pb-6 2xl:pb-12 max-w-[1264px] w-full mx-auto px-4 lg:px-8">
              <div className="bg-primary rounded-3xl py-5 lg::py-7 2xl:py-14 img_twos">
                <div className="flex flex-wrap md:flex-nowrap justify-between items-center">
                  <div className="textings px-4 md:pl-14 w-full md:w-2/5 text-center md:text-left"> 
                    <h2 className="text-base/5 md:text-xl/5 lg:text-xl 2xl:text-4xl font-semibold text-[#F6F7FB] mb-3 lg:mb-4 max-lg:whitespace-nowrap ">{homedata?.part2_heading4 ? homedata?.part2_heading4 : "Ready to start your journey?"}</h2>
                    <p className="text-sm lg:text-base text-[#E3E5ED] mb-4 lg:mb-8">{homedata?.part2_description6 ? homedata?.part2_description6 : "Experience our all-inclusive package for a seamless journey from start  to finish. We take care of everything you need in a closed-loop service, ensuring a hassle-free and enjoyable."}</p>
                    <Link className="max-md:mx-auto transition-all px-8 py-3.5 flex items-center justify-center gap-2 h-10 lg:h-[54px] outline-none rounded-xl w-fit text-sm 2xl:text-base text-[#F6F7FB] font-medium bg-primary border border-[#F6F7FB] hover:opacity-80 " to="/campervans">{homedata?.part2_button4 ? homedata?.part2_button4 : "Find a Campervan"}
                    <IoIosArrowRoundForward className="size-5 text-white" /></Link>
                  </div>
                  <div className="bgimages flex w-full mt-5 md:m-0 md:w-3/5 h-[280px] lg:h-[350px] xl:h-[410px]">
                    <img className="mx-auto md:ml-auto max-md:px-4 md:mr-10 w-full max-w-[380px] lg:max-w-[500px] xl:max-w-[610px]" src={homedata?.part2_journey2_image?methodModel.noImg(homedata?.part2_journey2_image):"assets/img/front/Home-Page-img.png"} alt="twoimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#F6F7FB] py-6 2xl:py-12">
              <div className="max-w-[1264px] w-full mx-auto px-4 lg:px-8">
              <h2 className="font-semibold text-secondary text-[15px] sm:text-xl lg:text-xl 2xl:text-4xl  mb-4 lg:mb-5 2xl:mb-8">{homedata?.part2_heading5 ? homedata?.part2_heading5 : "Trending Destinations"}</h2>
              <TrendingDestinationSlider />
              </div>
            </div>
            <div className="py-4 lg:py-6 2xl:py-12 ">
              <div className="max-w-[1264px] w-full mx-auto px-4 lg:px-8">
              <AboutUs />
              </div>
            </div>
            <div className="max-w-[1264px] w-full mx-auto px-4 lg:px-8">
              <div style={{ backgroundImage: `url(${homedata?.part3_list_background_image?methodModel.noImg(homedata?.part3_list_background_image):"assets/img/front/bg_cover.png"})` }} className="bg_bag !bg-right h-[250px] sm:h-[340px] lg:h-[410px] xl:h-[490px] rounded-3xl overflow-auto px-4 md:px-10 lg:px-[100px] flex items-center">
                  <div className="max-w-[794px] relative z-10 w-full">
                    <h2 className="text-sm sm:text-base lg:text-xl 2xl:text-[40px]/[56px] font-semibold text-[#F6F7FB] mb-4 lg:mb-10 max-w-[674px]">{homedata?.part3_heading2 ? homedata?.part3_heading2 : "Do you want to list your campervan? List your RV for free today and <br />earn up to $1,800 /week."}</h2>
                    <button type="button" className="px-5 xl:px-[30px] py-2 lg:py-[9px] xl:h-12 2xl:h-[54px] border border-white rounded-xl text-sm 2xl:text-base  text-white hover:bg-primary transition-all hover:border-transparent">{homedata?.part3_button ? homedata?.part3_button : "List Your RV"}</button>
                  </div>
                </div>
              </div>
              <div className="py:4 lg:py-6 2xl:py-12">
                <div className="max-w-[1264px] w-full mx-auto px-4 lg:px-8">
                <h2 className="font-semibold text-secondary text-xl lg:text-xl 2xl:text-4xl mb-4 lg:mb-5 2xl:mb-8">
                  {homedata?.part3_heading3 ? homedata?.part3_heading3 : "Popular Cities"}
                </h2>
                <div className="p-4 2xl:p-8 border border-gray-border flex flex-wrap gap-3 2xl:gap-6 rounded-3xl">
                  {cities && cities.map((itm: any, idx: number) => (
                    <div key={idx} onClick={e => navigate(itm?.pageUrl)} className="transition-all duration-300 border-[0.87px] border-gray-border px-4 py-2 hover:bg-primary hover:border-primary rounded-xl group text-center cursor-pointer">
                      <p className="group-hover:text-white text-sm lg:text-base text-secondary capitalize">{itm?.label}</p>
                    </div>
                  ))}
                </div>
                </div>
              </div>

                {/* <div className="mt-4 sm:mt-6 lg:mt-[60px]">
                <NewsLetter />
              </div> */}
            </div>
        </PageLayout>
      </HomeData.Provider>
    </  >
  );
};

export default Home;