import { useEffect, useState } from "react"
import PageLayout from "../../components/global/PageLayout"
import BookingConfirmation from "./BookingConfirmation"
import CartPage from "./CartPage"
import { useNavigate, useParams } from "react-router-dom";
import PaymentDetail from "./PaymentDetail"
import { FaCheck } from "react-icons/fa";
import { FiChevronLeft } from "react-icons/fi";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import environment from "../../environment";
import moment from "moment";
import methodModel from "../../methods/methods";
import { useDispatch } from "react-redux";
import { SEARCH_STATE } from "../../Redux/Action/Action";

const BookingCampervan = () => {
  const search = useSelector((state) => state.Reducer.search)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.User)
  const { id, bookingId } = useParams()
  const [tab, settab] = useState(1)
  const navigate = useNavigate()
  const [campervanDetails, setCampervanDetails] = useState()
  const [form, setForm] = useState({
    fullName: '', email: '', mobileNo: '', driverAge: '', licenseIssueCountry: '',
    pickUpDate: {}, dropOffDate: {}, pickUpTime: null, dropOffTime: null, cardNumber: '', expiry: '', cvc: '', country: '',
  })
  const [insurance, setInsurance] = useState([])
  const [extraItems, setExtraItems] = useState([])
  const [selectedInsurance, setSelectedInsurance] = useState()
  const [error, setError] = useState(false)
  const [couponCode, setCouponCode] = useState({ ...search?.couponCode, value: search?.couponCode?.value || '', allow: false })
  const [allCoupons, setAllCoupons] = useState([])
  const [termsConditions, setTermsConditions] = useState(false)
  const [quoteDetails, setQuoteDetails] = useState()

  const handleBackButton = () => {
    if (findAgentorCustomer()) {
      navigate('/campervanlist')
      return
    }
    if (tab == 1) {
      navigate(-1)
    } else if (tab == 2) {
      settab(tab - 1)
    } else {
      navigate('/')
    }
  }

  useEffect(() => {
    if (bookingId) {
      getBookingDetails()
    } else {
      setForm((prev) => ({
        ...prev,
        fullName: user?.role == 'agent' || user?.role == 'sub_agent' ? "" : user?.fullName,
        email: user?.role == 'agent' || user?.role == 'sub_agent' ? "" : user?.email,
        mobileNo: user?.role == 'agent' || user?.role == 'sub_agent' ? "" : user?.mobileNo,
        pickUpDate: { startDate: moment(search?.start_date || new Date()).format('YYYY-MM-DD'), endDate: moment(search?.start_date || new Date()).format('YYYY-MM-DD') },
        dropOffDate: { startDate: moment(search?.end_date || new Date()).format('YYYY-MM-DD'), endDate: moment(search?.end_date || new Date()).format('YYYY-MM-DD') },
        driverAge: search?.ageid?.label || null,
      }))
      getAllCoupons()
      if (id) getCampervanDetails()
    }
    if (methodModel.getPrams('status') == 'successfull') handleAfterPayment()
    // if (methodModel.getPrams('status') == 'failed') setDataInStates()
  }, [])

  // Scroll to top when ever tab is change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tab])

  const getBookingDetails = () => {
    if (bookingId) {
      loader(true)
      ApiClient.get(`Bookings?id=${bookingId}`).then(res => {
        if (res.success) {
          const pickupLocation = JSON.parse(res?.data?.pickUpLocation)
          const dropoffLocation = res?.data?.dropOffLocation ? JSON.parse(res?.data?.dropOffLocation) : ""
          const pickUpDate = moment(res?.data?.pickup_date).format("YYYY-MM-DD")
          const dropOffDate = moment(res?.data?.drop_off_date).format("YYYY-MM-DD")
          dispatch(SEARCH_STATE({ ...search, pickUpLocation: pickupLocation, dropOffLocation: dropoffLocation, startDate: res?.data?.pickup_date, endDate: res?.data?.drop_off_date }))
          setForm({
            ...res?.data,
            id: res?.data?.customer_id?.id || res?.data?.customer_id?._id,
            pickUpDate: { startDate: pickUpDate },
            dropOffDate: { startDate: dropOffDate },
            driverAge: res?.data?.driverAge || "",
            licenseIssueCountry: res?.data?.licenseIssueCountry || "",
          })
          setSelectedInsurance(JSON.parse(res?.data?.insurance))
          setExtraItems(JSON.parse(res?.data?.addOns))
          setQuoteDetails(res?.data)
          getAllCoupons()
          if (id) getCampervanDetails(pickUpDate, dropOffDate, pickupLocation?.value, dropoffLocation?.value)
        }
        loader(false)
      })
    }
  }

  // It hit when payment status is successfull and customer back from the payment screen
  const handleAfterPayment = () => {
    const payerId = methodModel.getPrams('PayerID')
    const paymentId = methodModel.getPrams('paymentId')
    // To complete the booking status with Paypal payment method
    if (payerId && paymentId && id && bookingId) {
      ApiClient.post(`executePaypalPayment`, { payer_id: payerId, payment_id: paymentId, campervan_id: id, booking_id: bookingId })
    }
    // After booking status complete with Stripe payment method
    settab(3)
  }

  // It get data from localStorage and set in states
  const setDataInStates = () => {
    setForm(JSON.parse(localStorage.getItem('form')))
    setSelectedInsurance(JSON.parse(localStorage.getItem('selectedInsurance')))
    setExtraItems(JSON.parse(localStorage.getItem('extraItems')))
    setQuoteDetails(JSON.parse(localStorage.getItem('quoteDetails')))
    localStorage.removeItem('form')
    localStorage.removeItem('selectedInsurance')
    localStorage.removeItem('extraItems')
    localStorage.removeItem('quoteDetails')
  }

  // To get the all coupons data
  const getAllCoupons = () => {
    ApiClient.get(`Coupon/all?status=active`).then(res => {
      if (res.success) {
        setAllCoupons(res?.data)
      }
    })
  }

  // Get the campervan detail
  const getCampervanDetails = (startDate = '', endDate = '', pickupLocation = "", dropoffLocation = "") => {
    loader(true)
    const pickUpLocationSiteCode = pickupLocation || search?.pickUpLocation?.value || ''
    const dropOffLocationSiteCode = dropoffLocation || search?.dropOffLocation?.value || search?.pickUpLocation?.value || ''
    const pickUpDate = startDate || search?.start_date || ''
    const dropOfDate = endDate || search?.end_date || ''
    const step3Payload = {
      method: "step3",
      vehiclecategorytypeid: search?.vehiclecategorytypeid || "",
      pickuplocationid: search?.pickUpLocation?.spaceship_id || "",
      pickupdate: search?.start_date ? moment(search?.start_date).format("DD/MM/YYYY") : "", // Change date format to RCM date format
      pickuptime: "10:00", // Pass static time because user choose only date as dinesh sir said
      dropofflocationid: search?.dropOffLocation?.spaceship_id || search?.pickUpLocation?.spaceship_id || "",
      dropoffdate: search?.start_date ? moment(search?.end_date).format("DD/MM/YYYY") : "", // Change date format to RCM date format
      dropofftime: "10:00", // Pass static time because user choose only date as dinesh sir said
      ageid: search?.ageid?.value || "",
      vehiclecategoryid: search?.vehiclecategoryid || ""
    }
    if (pickUpLocationSiteCode && pickUpDate && dropOfDate || search?.pickUpLocation?.spaceship_id && pickUpDate && dropOfDate) {
      ApiClient.post(`availabilityandprice/campervan?accountKey=${environment.agentAccountKey}&typeId=${'7d3dece7-ab86-e611-80e8-c4346bc5977c'}&pickUpLocation=${pickUpLocationSiteCode}&dropOffLocation=${dropOffLocationSiteCode}&pickUpDateTime=${pickUpDate}&dropOffDateTime=${dropOfDate}&driverAge=${23}&mergeSurchargesFees=${false}&id=${id}&user_id=${user?.id || user?._id || ''}&type=${search?.vehiclecategoryid ? "rcm" : ""}`, step3Payload, '', true).then(res => {
        if (res.success) {
          if (search?.vehiclecategoryid) {
            setCampervanDetails({ ...res?.data })
            if (!methodModel.getPrams('status') || !bookingId) {
              setExtraItems(res?.data?.extraItems_details?.map((item, index) => {
                return ({ Items: [{ id: res?.data?.extraItems_details?.[index]?.extrakmsid, Name: res?.data?.extraItems_details?.[index]?.name, Qty: 0, Price: { CurrencyCode: "AUD", Value: res?.data?.extraItems_details?.[index]?.price }, Total: { CurrencyCode: "AUD", Value: res?.data?.extraItems_details?.[index]?.price }, price: res?.data?.extraItems_details?.[index]?.price, totalPrice: res?.data?.extraItems_details?.[index]?.price }] })
              }))
              setInsurance(res?.data?.insurance_details?.map((item) => {
                return ({ ...item, ExcessType: item?.company_name, Price: { Value: item?.amount }, Total: { Value: item?.amount } })
              }))
            }
            setError(false)
            loader(false)
          } else {
            let response = res?.data?.campervan_details
            response = {
              ...response,
              Total: res?.data?.Total,
              DiscountPercentageApplied: res?.data?.DiscountPercentageApplied,
              DailyRate: res?.data?.DailyRate,
              CategoryCode: res?.data?.CategoryCode,
              Availability: res?.data?.Availability,
              RentalDays: res?.data?.RentalDays,
              TypeSlug: res?.data?.TypeSlug
            }
            let jucyImage = response?.gallery?.filter((item => {
              if (item?.type == "Image") return item
            }))
            let jucyAmenitiesDetails = response?.keyFeatures?.map((item) => {
              return ({ image: "/assets/img/front/seat.svg", name: item?.title })
            })
            setCampervanDetails({
              ...response, images: jucyImage?.map((item) => item?.original), beds: response?.sleepDescription,
              amenities_details: jucyAmenitiesDetails, terms: response?.specificationsDescription
            })
            setError(false)
            if (methodModel.getPrams('status') != 'successfull' && methodModel.getPrams('status') != 'failed') handleCreateQuote(response, pickUpLocationSiteCode, dropOffLocationSiteCode, pickUpDate, dropOfDate)
            else loader(false)
          }
        } else {
          setError('Campervan is not available on this locations and dates')
          if (methodModel.getPrams('status') != 'successfull' && methodModel.getPrams('status') != 'failed') setSelectedInsurance({})
          setCampervanDetails({ campervan_type: 'jucy' })
          loader(false)
        }
      })
    } else {
      ApiClient.get(`Campervan?id=${id}`).then(res => {
        if (res.success) {
          if (res?.data?.campervan_type == 'jucy') {
            let jucyImage = res?.data?.gallery?.filter((item => {
              if (item?.type == "Image") return item
            }))
            let jucyAmenitiesDetails = res?.data?.keyFeatures?.map((item) => {
              return ({ image: "/assets/img/front/seat.svg", name: item?.title })
            })
            setCampervanDetails({
              ...res?.data, images: jucyImage?.map((item) => item?.original), beds: res?.data?.sleepDescription,
              amenities_details: jucyAmenitiesDetails, terms: res?.data?.specificationsDescription
            })
          } else {
            setCampervanDetails(res?.data)
            setForm((prev) => ({
              ...prev,
              pickUpDate: { startDate: moment(res?.data?.start_date || new Date()).format('YYYY-MM-DD'), endDate: moment(res?.data?.start_date || new Date()).format('YYYY-MM-DD') },
              dropOffDate: { startDate: moment(res?.data?.end_date || new Date()).format('YYYY-MM-DD'), endDate: moment(res?.data?.end_date || new Date()).format('YYYY-MM-DD') }
            }))
          }
        }
        loader(false)
      })
    }
  }

  // Hit a dummy create quote api to get the insurance and extraItems for user to choose
  const handleCreateQuote = (response, pickUpLocationSiteCode, dropOffLocationSiteCode, pickUpDate, dropOfDate) => {
    ApiClient.thirdPartyPost(`https://lanier.test.jucy.cloud/api/Account/${environment?.agentAccountKey}/2.0/CreateQuote?firstName=${user?.firstName || "Test"}&lastName=${user?.lastName || "Test"}&email=${user?.email || "test@yopmail.com"}&categoryCode=${response?.CategoryCode}&pickUpLocationCode=${pickUpLocationSiteCode}&dropOffLocationCode=${dropOffLocationSiteCode}&pickUpDateTime=${pickUpDate}&dropOffDateTime=${dropOfDate}`, {}, '', true).then(res => {
      if (res?.ResponseType == 'Success') {
        const insuranceArray = res?.Data?.InsuranceProducts?.[0]?.Items
        insuranceArray.sort((a, b) => {
          const order = ['Basic', 'Standard', 'Full'];
          return order.indexOf(a.ExcessType) - order.indexOf(b.ExcessType);
        });
        setInsurance(res?.Data?.InsuranceProducts?.map((item) => {
          return ({ ...item, Items: insuranceArray })
        }))
        if (!bookingId) {
          setExtraItems(res?.Data?.SecondaryProducts?.map((itm, index) => {
            return ({
              ...itm, Items: itm?.Items?.map((item, index) => {
                return ({ ...item, price: item?.Price?.Value, totalPrice: item?.Total?.Value, Qty: 0 })
              })
            })
          }))
        }
      } else {
        setError('Campervan is not available on this location and dates.')
        setSelectedInsurance({})
        setCampervanDetails({ campervan_type: 'jucy' })
      }
      loader(false)
    })
  }

  // Calculate the total amount of add ons data
  const findTotalAddOns = () => {
    let total = 0
    extraItems && extraItems?.map((itm, i) => {
      itm?.Items?.map((item, index) => {
        if (item?.Qty > 0) {
          total += Number(item?.Total?.Value)
        }
      })
    })
    return Number(total)
  }

  // Calculate the total and campervan price behalf of type
  const totalCampervanPrice = (type = 'total') => {
    let total = 0
    if (campervanDetails?.campervan_type == 'jucy') {
      total = Number(campervanDetails?.Total?.Value || 0) + Number(selectedInsurance?.Total?.Value || 0) + findTotalAddOns() - Number(discountApplied().replace('$', ''))
    } else if (type == 'campervanOnly') {
      total = Number(campervanDetails?.defaultRate) + Number(campervanDetails?.serviceFees)
    } else if (campervanDetails?.campervan_type == "spaceship") {
      total = Number(campervanDetails?.defaultRate) + Number(selectedInsurance?.Total?.Value || 0) + findTotalAddOns() - Number(discountApplied().replace('$', ''))
    } else {
      total = Number(campervanDetails?.defaultRate) + Number(campervanDetails?.security_deposite) + Number(campervanDetails?.serviceFees)
    }
    return parseFloat(total || 0).toFixed(2)
  }

  // Check and provide the applied coupon code discount in percentage or dollars
  const discountApplied = () => {
    if (couponCode?.discount_type == 'Percentage') {
      let amount = campervanDetails?.Total?.Value || totalCampervanPrice()
      let calculateAmount = (couponCode?.discount_value * amount) / 100
      return (`$${parseFloat(calculateAmount || 0).toFixed(2)}`)
    } else {
      return `$${couponCode?.discount_value || 0}`
    }
  }

  // To check who is login agent or customer
  const findAgentorCustomer = () => {
    if (user?.role == "agent" || user?.role == "sub_agent") return true
    else return false
  }

  return (
    <>
      <PageLayout>
        <div className="max-w-[1264px] w-full mx-auto px-4 lg:px-8 mt-5">
          <div className="flex items-center cursor-pointer" onClick={e => handleBackButton()}>
            <FiChevronLeft />
            <p className="text-secondary ms-1"> Back</p>
          </div>
          {findAgentorCustomer() ? null :
            <ul className="flex items-center w-full justify-between sm:pt-[16px] pt-1 px-0.5 sm:px-[15px]">
              <li className={`w-[47%] after:content-[''] after:w-full ${tab == 1 ? 'after:bg-[#9BAFED]' : 'after:bg-primary'} after:h-[16px] sm:after:h-[20px] after:absolute after:-right-[32px] sm:after:-right-[37px] relative after:top-[10px] sm:after:top-[13px]`}>
                <p className={`${tab == 1 ? 'bg-primary' : 'bg-primary'} w-9 sm:w-12 h-9 sm:h-12 rounded-full flex items-center justify-center text-[#F6F7FB] text-lg z-10	relative`}>
                  {tab == 1 ? 1 : <FaCheck className="text-[#ffffff] w-3 lg:w-4 h-3 lg:h-4" />}
                </p>
                <h4 className="text-base font-medium text-secondary w-33% -ms-[30px] mt-4 hidden md:block ml-0 lg:ml-3">
                  Let’s get a bit info
                </h4>
              </li>
              <li className={`w-[47%] after:content-[''] after:w-full ${tab <= 2 ? 'after:bg-[#9BAFED]' : 'after:bg-primary'} after:h-[16px] sm:after:h-[20px] after:absolute after:-right-[32px] sm:after:-right-[37px] relative after:top-[10px] sm:after:top-[13px]`}>
                <p className={`
              
               bg-primary w-9 sm:w-12 h-9 sm:h-12 rounded-full flex items-center justify-center text-[${tab == 1 ? '#F6F7FB' : '#F6F7FB'}] text-[18px] z-10	relative`}>
                  {tab <= 2 ? 2 : <FaCheck className="text-[#ffffff] w-3 lg:w-4 h-3 lg:h-4" />}
                </p>
                <h4 className="text-base font-medium text-secondary -ms-[70px] mt-4 hidden md:block">Review your request & pay</h4>
              </li>
              <li className="">
                <p className={`bg-primary w-9 sm:w-12 h-9 sm:h-12 rounded-full flex items-center justify-center text-[${tab == 1 ? '#F6F7FB' : '#F6F7FB'}] text-[18px] z-10	relative`}>
                  {tab <= 2 ? 3 : <FaCheck className="text-[#ffffff] w-3 lg:w-4 h-3 lg:h-4" />}
                </p>
                <h4 className="text-base font-medium text-secondary -ms-[30px]  mt-4 hidden md:block">
                  Confirmation{" "}
                </h4>
              </li>
            </ul>
          }
        </div>
        <div className="">
          {tab == 1 ?
            <CartPage tab={tab} settab={settab} user={user} quoteDetails={quoteDetails} setQuoteDetails={setQuoteDetails} termsConditions={termsConditions} setTermsConditions={setTermsConditions} getCampervanDetails={getCampervanDetails} couponCode={couponCode} setCouponCode={setCouponCode} allCoupons={allCoupons} discountApplied={discountApplied} id={id} error={error} setError={setError} campervanDetails={campervanDetails} form={form} setForm={setForm} insurance={insurance} extraItems={extraItems} setExtraItems={setExtraItems} selectedInsurance={selectedInsurance} setSelectedInsurance={setSelectedInsurance} findTotalAddOns={findTotalAddOns} totalCampervanPrice={totalCampervanPrice} findAgentorCustomer={findAgentorCustomer} /> :
            tab == 2 ?
              <PaymentDetail tab={tab} settab={settab} form={form} setForm={setForm} campervanDetails={campervanDetails} totalCampervanPrice={totalCampervanPrice} findTotalAddOns={findTotalAddOns} selectedInsurance={selectedInsurance} couponCode={couponCode} setCouponCode={setCouponCode} allCoupons={allCoupons} discountApplied={discountApplied} error={error} />
              :
              <BookingConfirmation form={form} search={search} campervanDetails={campervanDetails} extraItems={extraItems} selectedInsurance={selectedInsurance} findTotalAddOns={findTotalAddOns} />
          }
        </div>
      </PageLayout>
    </>
  )
}

export default BookingCampervan