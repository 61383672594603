import { useEffect, useState, Fragment } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-js-pagination";
import ApiClient from "../../methods/api/apiClient";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import loader from "../../methods/loader";
import { Tooltip } from "antd";
import Select from 'react-select';
import { FiEdit2 } from "react-icons/fi";
import { PiEyeLight } from "react-icons/pi";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { colourStyles } from "../../utils/selectStyle";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";


const BookingListing = () => {
  const user = useSelector((state) => state.User);
  const [filter, setfilter] = useState({ page: 1, count: 10, campervan_type: '', booking_status: '' });
  const [total, settotal] = useState(0);
  const [data, setdata] = useState([]);
  let [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  const [action, setaction] = useState({ type: "status", id: "", status: "" });

  const brandType = [
    { value: 'admin', label: 'Peer To Peer' },
    { value: 'jucy', label: 'Jucy' }
  ]
  const bookingStatus = [
    { value: 'completed', label: 'Completed' },
    { value: 'failed', label: 'Failed' },
    { value: 'pending', label: 'Pending' },
  ]

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = (p = {}) => {
    let filters = { booking_status: filter?.booking_status?.value, campervan_type: filter?.campervan_type?.value, ...p, agent_id: user?.id || user?._id, page: filter?.page, count: filter?.count };
    if (user?.role == 'customer') {
      delete filters?.agent_id
      filters = { ...filters, customer_id: user?.id || user?._id }
    }
    loader(true)
    ApiClient.get(`Bookings/all`, filters).then((res) => {
      if (res.success) {
        setdata(res?.data)
        settotal(res?.total)
      }
      loader(false)
    });
  };

  // It is the common function to handle the all filters
  const handleFilters = (e, key) => {
    setfilter(prev => ({ ...prev, [key]: e }))
    getData({ [key]: e?.value })
  }

  const handlePagination = (e) => {
    setfilter({ ...filter, page: e });
    getData({ page: e });
  }
  // It handle the all actions like edit, view, delete with the help of key arugment
  const handleActions = (key, item) => {
    if (key == 'edit') {
      navigate(`/bookingcampervan/${item?.campervan_id}/${item?._id || item?.id}`)
    } else if (key == 'view') {
      navigate(`/booking/detail/${item}`)
    } else {
      setIsOpen(true)
      setaction({ type: "delete", id: item });
    }
  }
  // It is used for the modal action
  const onActionDialogOk = () => {
    ApiClient.put(`cancelBooking?id=${action?.id || action?._id}`).then((res) => {
      if (res.success) {
        setIsOpen(false);
        getData();
      }
    });
  };

  return (
    <>
      <Layout>
        <div className="w-full pb-3 border border-gray-border rounded-2xl p-4 2xl:p-6">
          <div className="flex flex-wrap justify-between max-sm:flex-col mb-3 2xl:mb-5 gap-3 lg:gap-6 sm:items-center">
            <div className="flex flex-col ">
            <h2 className="whitespace-nowrap text-base lg:text-xl 2xl:text-2xl font-semibold text-secondary mb-1">My Booking</h2>
            <h2 className="text-base font-normal text-gray-light"> View your previous booking.</h2>
            </div>
            <div className="flex flex-col md:flex-row gap-3">
              <Select placeholder='All Brand Types' styles={colourStyles} options={brandType} value={filter?.campervan_type} onChange={e => handleFilters(e, 'campervan_type')} isClearable classNamePrefix="react" />
              <Select placeholder='Booking Status' styles={colourStyles} options={bookingStatus} value={filter?.booking_status} onChange={e => handleFilters(e, 'booking_status')} isClearable classNamePrefix="react" />
            </div>
          </div>
          <div className="scrollbar !overflow-x-auto">
            <table className="w-full text-left ">
              <thead className="bg-[#F6F7FB]">
                <tr className="text-base sm:text-lg whitespace-nowrap text-secondary h-10">
                  <th className="min-w-[60px] py-2.5 px-6 font-medium text-sm">Name</th>
                  <th className="min-w-[100px] py-2.5 px-6 font-medium text-sm">Email</th> 
                  <th className="min-w-[60px] py-2.5 px-6 font-medium text-sm ">Campervan</th>
                  <th className="min-w-[60px] py-2.5 px-6 font-medium text-sm ">Brand Type</th>
                  <th className="min-w-[60px] py-2.5 px-6 font-medium text-sm">Amount</th>
                  <th className="min-w-[60px] py-2.5 px-6 font-medium text-sm">Status</th>
                  <th className="min-w-[60px] py-2.5 px-6 font-medium text-sm">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.length == 0 ?
                  <tr className="text-center my-4 h-[72px] ">
                    <td colSpan={6}>No Records Found</td>
                  </tr>
                  :
                  <>
                    {data &&
                      data.map((item, i) => {
                        return (
                          <tr key={i} className="h-[60px] text-sm 2xl:text-base font-normal text-gray-light">
                            <td className="p-4 border-b border-gray-border capitalize">
                              <span className="fs-5">{item?.firstName ? `${item?.firstName} ${item?.lastName}` : item?.fullName}</span>
                            </td>
                            <td className="p-4 border-b border-gray-border">{item?.email}</td>
                            
                            <td className="p-4 border-b border-gray-border capitalize">{item?.campervan_type == 'admin' ? 'Peer To Peer' : item?.campervan_type}</td>
                            <td className="p-4 border-b border-gray-border">${item?.campervan_price}</td>
                            <td className={`p-4 border-b border-gray-border capitalize`}><div className={`w-fit py-1.5 px-3 rounded-lg text-xs font-normal ${item?.booking_status === "completed" ? "bg-[#1DBF73]/10 text-[#1DBF73]":"bg-primary/10 text-primary"} flex items-center justify-center`}> {item?.booking_status} </div></td>
                            <td className="p-4 border-b border-gray-border">
                              <div className="flex gap-2 text-gray-500 items-center">
                                {item?.booking_status != "completed" &&
                                  <Tooltip title="Edit" className="cursor-pointer">
                                    <button className="cursor-pointer hover:opacity-80 !text-primary flex items-center justify-center text-xl" onClick={(e) => handleActions("edit", item)}>
                                      <FiEdit2 />
                                    </button>
                                  </Tooltip>
                                }
                                <Tooltip title="View" className="cursor-pointer">
                                  <button className=" cursor-pointer hover:opacity-80   !text-gray-light flex items-center justify-center text-xl" onClick={(e) => handleActions("view", item?._id || item?.id)}>
                                    <PiEyeLight />
                                  </button>
                                </Tooltip>
                                {item?.booking_status != "completed" &&
                                  <Tooltip title="Cancel" className="cursor-pointer">
                                    <button className="cursor-pointer hover:opacity-80  !text-red-500 flex items-center justify-center text-xl" onClick={(e) => handleActions("delete", item?._id || item?.id)}>
                                      <MdOutlineCancel />
                                    </button>
                                  </Tooltip>
                                }
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </>}
              </tbody>
            </table>
          </div>
          {filter?.count < total ? (
            <div className="mt-4 lg:mt-6">
              <Pagination
                  prevPageText={<IoIosArrowRoundBack className="text-xl md:text-2xl font-extralight absolute md:left-5 left-0 top-1.5 md:top-2" />}
                  nextPageText={<IoIosArrowRoundForward className="text-xl md:text-2xl font-extralight absolute md:right-6 right-0 top-1.5 md:top-2" />}
                  activePage={filter?.page}
                  itemsCountPerPage={filter?.count}
                  totalItemsCount={total}
                  pageRangeDisplayed={7}
                  onChange={handlePagination}
                  hideFirstLastPages={true}
                />
            </div>
          ) : null}
        </div>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <img src="/assets/img/img-2.png" className="mx-auto" />
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 text-center">
                        Do you really want to cancel this booking ?
                      </p>
                    </div>
                    <div className="text-center mt-6">
                      <button variant={action?.type == "status" ? "solid" : "warning"} onClick={(e) => onActionDialogOk()}>
                        <div className="">
                          <span className="flex items-center gap-2 button bg-red-600 md:px-5 lg:px-8 border hover:bg-red-400 transition duration-150 ease-out hover:ease-in text-white  border-red-300  rounded-lg h-11 px-8 py-2">
                            <MdOutlineCancel /> Cancel
                          </span>
                        </div>
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </Layout>
    </>
  );
};

export default BookingListing;
