import React, { useState } from "react";
import InputSelect from "../../components/common/InputSelect";
import { Link } from "react-router-dom";
// import DatePicker from "./DatePicker";
import DatePicker from "../../components/common/SearchBanner/DatePicker";

interface CampervanDetailProps {
  tab: number;
  settab: React.Dispatch<React.SetStateAction<number>>;
}

const CampervanDetail: React.FC<CampervanDetailProps> = ({ settab }) => {
  const country = [
    { name: "Australia" },
    { name: "Pakistan" },
    { name: "England" },
  ];

  const [filters, setFilters]: any = useState({
    pickUpLocation: "",
    dropOffLocation: "",
    start_date: "",
    end_date: "",
    isReturn: true,
    isDriverAge: false,
  });

  const [selectedCountry, setSelectedCountry] = useState(country[0]);

  return (
    <div className={`max-w-[1264px] w-full mx-auto px-4 lg:px-8 mt-6 2xl:mt-12`}>
      <h3 className="text-xl 2xl:text-2xl/9 font-semibold text-secondary mb-3">
        Campervan Details
      </h3>
      <div className="grid sm:grid-cols-2 gap-3  mb-6 2xl:mb-12">
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Campervan name</label>
          <input
            type="text"
            placeholder='Campervan Name'
            className="w-full rounded-lg border-0  bg-white !h-[47px] px-4 py-[18px] text-base font-normal text-secondary  focus:ring-offset-[1.2px] ring-1 ring-[#CDCDCD] focus:ring-primary outline-none appearance-none"
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Year Of Registration</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none "
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Manufacturer</label>
          <InputSelect
            classes={
              "w-full cursor-pointer  focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none"
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Registration Number</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none"
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Color</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none"
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>RV Class</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none"
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>RV Make</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none"
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>RV Series</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none"
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Country</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none"
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Region</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none"
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Cities</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none"
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full ">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>RV Series</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0  appearance-none"
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full ">
        <DatePicker
                classes='!rounded-lg !h-[47px] !border-[#CDCDCD] '
                containerClassName="relative mt-8" 
                label="Start Date & Time"
                startDate={filters?.start_date}
                endDate={filters?.end_date}
                onChange={(e: any) => {
                  setFilters({ ...filters, start_date: e.startDate, end_date: e.endDate })
                }}
                placeholder="MM/DD/YYYY" 
                classNamePrefix="react"
              />

          {/* <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Start Date & Time</label>
          <input type="date" name="" id="" className='rounded-lg w-full border border-[#CDCDCD] cursor-pointer outline-none bg-white h-[60px] px-4 py-[18px] appearance-none' /> */}
        </div>
        <div className="w-full">
        <DatePicker
                classes='!rounded-lg !h-[47px] !border-[#CDCDCD]'
                label="Return Date"
                showEndDate={true}
                startDate={filters?.start_date}
                endDate={filters?.end_date}
                onChange={(e: any) => {
                  setFilters({ ...filters, start_date: e.startDate, end_date: e.endDate })
                }}
                placeholder="MM/DD/YYYY"
              />
          {/* <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>End Date & Time</label>
          <input type="date" name="" id="" className='rounded-lg w-full border border-[#CDCDCD] cursor-pointer outline-none bg-white h-[60px] px-4 py-[18px] appearance-none' /> */}
        </div>
      </div>
      <h3 className="text-xl 2xl:text-2xl/9 font-semibold text-secondary mb-3">
        Registration
      </h3>
      <div className="grid sm:grid-cols-2 gap-3  mb-6 2xl:mb-12">
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Registration Country</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 "
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Transmission</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 "
            }
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
      </div>
      <h3 className="text-xl 2xl:text-2xl/9 font-semibold text-secondary mb-3">
        Specifications
      </h3>
      <div className="grid sm:grid-cols-2 gap-3  mb-6 2xl:mb-12">
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Mileage</label>
          <InputSelect
            classes={"w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-[#CDCDCD] focus:ring-primary outline-none  rounded-lg !border-0 appearance-none"}
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
            // textLabel={"Kilometers"}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Fuel</label>
          <InputSelect
            classes={
              "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none "
            }
            labelBg={"bg-white"}
            // placeholder={'Country'}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Vehicle weight</label>
          <InputSelect
            classes={"w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-[#CDCDCD] focus:ring-primary outline-none rounded-lg !border-0  appearance-none"}
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
            // textLabel={"Tons"}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Vehicle length (Optional)</label>
          <InputSelect
            classes={"w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-[#CDCDCD] focus:ring-primary outline-none rounded-lg !border-0 appearance-none "}
            labelBg={"bg-white"}
            options={country}
            value={selectedCountry}
            onChange={setSelectedCountry}
            // textLabel={"Meters"}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className='text-sm 2xl:text-base font-medium text-secondary block mb-2'>Vehicle acquisition value</label>
          <div className="flex">
            <input
              type="text"
              name="text"
              placeholder='Country'
              className="!mb-0 border-0 focus:ring-offset-[1.2px] ring-1 ring-[#CDCDCD] focus:ring-primary outline-none rounded-lg !border-0 w-full h-[60px] p-4 block appearance-none"
            />
            {/* <span className="whitespace-nowrap px-6 py-[18px] -my-[1px] rounded-r-lg bg-[#E6E6E6] text-base font-normal text-secondary">
              $-Dollar
            </span> */}
          </div>
          {/* <span className="text-base font-normal text-secondary/70 mt-6 block">Number Only</span> */}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center gap-3 sm:gap-5 mb-12">
        <button
          type="button"
          onClick={() => settab(3)}
          className=" text-sm block h-[60px] w-[205px] text-white bg-primary rounded-lg hover:opacity-80">
          Save and continue
        </button>
        <Link
          to=""
          onClick={() => settab(3)}
          className="text-sm lg:text-base 2xl:text-lg/5 font-medium text-gray-light flex items-center justify-center h-10 xl:h-12 2xl:h-[54px] w-full sm:max-w-[152px] rounded-xl border border-gray-border hover:bg-primary hover:text-[#F6F7FB] transition-all"
        >
          Skip Setup
        </Link>
      </div>
    </div>
  );
};

export default CampervanDetail;
