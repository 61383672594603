import StarRatings from 'react-star-ratings';
import { GoArrowRight } from "react-icons/go";

const Reviews = () => {
  return (
    <>
      <div className="icons_ilustion mb-4 2xl:mb-12">
        <div className="flex flex-col">
          <h2 className="font-semibold text-secondary text-lg lg:text-xl 2xl:text-2xl mb-2">Reviews</h2>
            <div className="rating-section">
                <div className="ratings-star mb-4 2xl:mb-8 flex items-center gap-3 flex-wrap">
                  <p className="text-left text-sm text-gray-light">Overall ratings for 50 reviews </p>
                  <StarRatings
                    rating={4.5}
                    starSpacing="3px"
                    starRatedColor="#FEB602"
                    starHoverColor="#FEB602"
                    starDimension="16"
                    numberOfStars={5}
                    name='rating'
                  // changeRating={() => { window.alert("rating clicked") }}
                  />
                  <span className="block text-sm font-normal text-secondary">(4.8)</span>
                </div>
              <div className="border-b border-gray-border pb-4 2xl:pb-8">
                <div className="max-w-[730px] w-full">
                  <div className="flex flex-col md:flex-row justify-between max-md:gap-2 mb-2 lg:mb-4 2xl:mb-5">
                      <div className="flex items-center gap-6">
                        <p className="w-32 text-left text-sm sm:text-lg text-secondary font-normal">Accuracy </p>
                        <StarRatings
                          rating={4}
                          starSpacing="3px"
                          starRatedColor="#FEB602"
                          starHoverColor="#FEB602"
                          starDimension="16"
                          numberOfStars={5}
                          name='rating'
                        // changeRating={() => { window.alert("rating clicked") }}
                        />
                      </div>
                      <div className="flex items-center gap-6">
                        <p className="w-32 text-left text-sm sm:text-lg text-secondary font-normal">Pick Up</p>
                        <StarRatings
                          rating={4.5}
                          starSpacing="3px"
                          starRatedColor="#FEB602"
                          starHoverColor="#FEB602"
                          starDimension="16"
                          numberOfStars={5}
                          name='rating'
                        // changeRating={() => { window.alert("rating clicked") }}
                        />
                      </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between max-md:gap-2 mb-2.5 lg:mb-5">
                      <div className="flex items-center gap-6">
                        <p className="w-32 text-left text-sm sm:text-lg text-secondary">Amenities </p>
                        <StarRatings
                          rating={4}
                          starSpacing="3px"
                          starRatedColor="#FEB602"
                          starHoverColor="#FEB602"
                          starDimension="16"
                          numberOfStars={5}
                          name='rating'
                        // changeRating={() => { window.alert("rating clicked") }}
                        />
                      </div>
                      <div className="flex items-center gap-6">
                        <p className="w-32 text-left text-sm sm:text-lg text-secondary">Cleanliness </p>
                        <StarRatings
                          rating={4.5}
                          starSpacing="3px"
                          starRatedColor="#FEB602"
                          starHoverColor="#FEB602"
                          starDimension="16"
                          numberOfStars={5}
                          name='rating'
                        // changeRating={() => { window.alert("rating clicked") }}
                        />
                      </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between max-md:gap-2">
                      <div className="flex items-center gap-6">
                        <p className="w-32 text-left text-sm sm:text-lg text-secondary">Rental Value</p>
                        <StarRatings
                          rating={4}
                          starSpacing="3px"
                          starRatedColor="#FEB602"
                          starHoverColor="#FEB602"
                          starDimension="16"
                          numberOfStars={5}
                          name='rating'
                        // changeRating={() => { window.alert("rating clicked") }}
                        />
                      </div>
                      <div className="flex items-center gap-6">
                        <p className="w-32 text-left text-sm sm:text-lg text-secondary">Communication</p>
                        <StarRatings
                          rating={4.5}
                          starSpacing="3px"
                          starRatedColor="#FEB602"
                          starHoverColor="#FEB602"
                          starDimension="16"
                          numberOfStars={5}
                          name='rating'
                        // changeRating={() => { window.alert("rating clicked") }}
                        />
                      </div>
                  </div>
                </div>
              </div>
                <div className="pt-4 2xl:pt-5 flex flex-col gap-3 2xl:gap-6">
                  {[...Array(3)].map((_itm, idx) => (
                    <div key={idx} className="reviews_list ">
                      <div className="flex justify-between items-start">
                        <div className="user-img flex items-center gap-3">
                          <img className="h-12 w-12 object-contain" alt='' src="/assets/img/front/p1.png" />
                          <div className="user-data">
                            <p className="font-semibold text-secondary text-lg/6 mb-1">John ben</p>
                            <p className="font-normal text-black/50 text-xs">February 2024</p>
                          </div>
                        </div>
                        <StarRatings
                          rating={4.5}
                          starSpacing="3px"
                          starRatedColor="#FEB602"
                          starHoverColor="#FEB602"
                          // starDimension="24"
                          starDimension={ window.innerWidth >= 768 ? "24px" : "16px"}
                        
                          numberOfStars={5}
                          name='rating'
                        // changeRating={() => { window.alert("rating clicked") }}
                        />
                      </div>
                        <div className='border-b border-gray-border py-4 2xl:py-6'>
                        <p className="font-normal text-sm text-gray-light  line-clamp-3">
                          Jennifer was great to work with. The Rv is very nice and clean. Pretty much everything you need. They delivered and set up for us which was great! The price is too high to rent an RV. And since they delivered it to us we didn't need the insurance they required. Think that was...
                        </p>
                        </div>
                    </div>
                  ))}
                </div>
              <div className="pt-4 2xl:pt-6 flex justify-center items-center">
                <button className="flex items-center justify-center gap-2 mx-auto h-10 xl:h-12 2xl:h-[54px] max-w-[150px] lg:max-w-[170px] 2xl:max-w-[235px] w-full text-primary text-sm 2xl:text-base font-normal border border-primary rounded-xl hover:bg-primary hover:text-white group hover:border-transparent transition-all">Show All reviews
                <GoArrowRight className="text-primary size-5 group-hover:text-white" />
                </button>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Reviews