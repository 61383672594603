import React, { useEffect, useState } from "react"
import Slider from "react-slick";
import { campervanCardSlider } from "../../../utils/slick-settings";
import { RxLightningBolt } from "react-icons/rx"
import { Link, useNavigate, useParams } from "react-router-dom";
import ApiClient from "../../../methods/api/apiClient";
import ImageSlider from "../ImageSlider";
import ImageSliderDirect from "../ImageSliderDirect";
import methodModel from "../../../methods/methods";
import { useSelector } from "react-redux";
import loader from "../../../methods/loader";
import environment from "../../../environment";
import { CiHeart } from "react-icons/ci";
import { IoMdHeart } from "react-icons/io";
import CryptoJS from 'crypto-js';
import moment from "moment";

const CampervanSlider: React.FC = () => {
  const jucyAmenitiesImages = useSelector((state: any) => state.Reducer.jucyImages)
  const { id } = useParams()
  const user = useSelector((state: any) => state.User)
  const [campervans, setCampervans]: any = useState([])
  const [jucyCampervans, setJucyCampervans]: any = useState()
  const search = useSelector((state: any) => state.Reducer.search)
  const API_KEY = environment.AU_SPACESHIPS_API_KEY
  const SHARED_SECRET = environment.AU_SPACESHIPS_SHARED_SECRET || ''
  const urlPath = window.location.pathname
  const navigate = useNavigate()

  const images1 = [
    "assets/img/front/camper1.svg",
    "assets/img/front/camper2.svg",
  ];

  useEffect(() => {
    getCampervans()
  }, [])

  const getCampervans = () => {
    if (!id) loader(true)
    let payload = { ...search }
    if (payload?.pickUpLocation?.label === payload?.pickUpLocation?.value) delete payload?.pickUpLocation?.value
    if (payload?.dropOffLocation?.label === payload?.dropOffLocation?.value) delete payload?.dropOffLocation?.value

    const pickUpLocationSiteCode = payload?.pickUpLocation?.value || ''
    const dropOffLocationSiteCode = payload?.dropOffLocation?.value || payload?.pickUpLocation?.value || ''
    const pickUpDate = payload?.start_date || ''
    const dropOfDate = payload?.end_date || ''

    // Spaceship api payload, api key and signature
    const spaceShipPayload = {
      method: "step2",
      vehiclecategorytypeid: "0",
      pickuplocationid: payload?.pickUpLocation?.spaceship_id,
      pickupdate: moment(payload?.start_date).format('DD/MM/YYYY'), // Change the date format
      pickuptime: "10:00",
      dropofflocationid: payload?.dropOffLocation?.spaceship_id || payload?.pickUpLocation?.spaceship_id,
      dropoffdate: moment(payload?.end_date).format('DD/MM/YYYY'), // Change the date format
      dropofftime: "10:00",
      ageid: 9
    }
    const signature = CryptoJS.HmacSHA256(JSON.stringify(spaceShipPayload), SHARED_SECRET).toString(CryptoJS.enc.Hex)

    if (pickUpLocationSiteCode && pickUpDate && dropOfDate && id) {
      ApiClient.post(`availabilityAndPrice?typeId=${'7d3dece7-ab86-e611-80e8-c4346bc5977c'}&pickUpLocation=${pickUpLocationSiteCode}&dropOffLocation=${dropOffLocationSiteCode}&pickUpDateTime=${pickUpDate}&dropOffDateTime=${dropOfDate}&accountKey=${environment?.agentAccountKey}&driverAge=${23}&mergeSurchargesFees=${false}&apikey=${API_KEY}`, spaceShipPayload, '', true).then(res => {
        if (res?.data?.ResponseType == 'Success') {
          const campervanIds: any = []
          setCampervans(res?.data?.Data?.[1]?.Categories)
          res?.data?.Data?.[1]?.Categories?.map((item: any) => {
            if (item?.Availability != 'StopSell' && item?.campervan_type == 'jucy') {
              campervanIds.push(item?.Id)
            }
          })
          ApiClient.get(`all/Campervan`, { jucy_campervan_id: String(campervanIds) }).then(res => {
            if (res.success) {
              setJucyCampervans(res?.data)
            }
          })
        } else {
          setCampervans([])
        }
      })
    } else {
      ApiClient.get(`all/Campervan?status=active`).then(res => {
        if (res.success) {
          setCampervans(res?.data)
        }
        if(urlPath != "/") loader(false)
      })
    }
  }

  const showJucyImages = (item: any, index: any) => {
    let sliderImages = item
    sliderImages = item?.filter((itm: any) => {
      if (itm?.type == "Image") return itm?.original
    })
    sliderImages = sliderImages?.slice(0, 5)?.map((value: any) => value?.original)
    return sliderImages
  }

  const calculateDays = (startDate: any, endDate: any) => {
    if (endDate < startDate) {
      return 0;
    }
    const diffInMilliseconds = Math.abs(endDate - startDate);
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
    return Math.ceil(diffInDays);
  };

  const campervanPrice = (item: any, type: 'Perday') => {
    let price: any = Number(item?.defaultRate || 0)
    if (type == 'Perday') {
      let days = calculateDays(new Date(item?.start_date), new Date(item?.end_date))
      price /= days
    }
    return parseFloat(price || 0).toFixed(2)
  }

  const showJucyCampervanDetail = (item: any, index: number, key: string) => {
    const data = jucyCampervans?.find((itm: any) => itm?.jucy_campervan_id == item?.Id)
    if (key == 'gallery') {
      return showJucyImages(data?.gallery, index)
    } else {
      return data?.[key]
    }
  }

  const handleFavoriteUnFavorite = (id: string) => {
    let token = localStorage.getItem('token')
    if (!token) navigate('/login')
    loader(true)
    ApiClient.post(`favourite`, { campervan_id: id }).then(res => {
      if (res.success) {
        getCampervans()
      }
      loader(false)
    })
  }

  const findIsFavourite = (favoriteDetails: any) => {
    let value = []
    value = favoriteDetails?.filter((item: any) => {
      if (item?.fav_user_id == user?.id) {
        return item
      } else {
        return false
      }
    })
    return value?.[0]?.isFavourite
  }

  // Check the jucy image based on the name if exists return the image
  const checkJucyAmenitiesImage = (name: string) => {
    let data = jucyAmenitiesImages && jucyAmenitiesImages?.find((item: any, index: number) => {
      if (item?.name == name) {
        return item
      }
    })
    return data?.image
  }

  return (
    <Slider {...campervanCardSlider} className="-mx-3 campervan">
      {campervans && campervans?.map((item: any, index: number) => (
        <>

          {item?.Availability == 'StopSell' ? null :
          <div className="px-2 2xl:px-4">
            <div className="flex flex-col gap-5 min-h-[542px] bg-white p-2 rounded-2xl" key={index}>
              <div className="flex-grow">
              <div className="relative overflow-hidden">
                {item?.campervan_type == 'jucy' || item?.Id ?
                  <ImageSliderDirect images={item?.gallery ? showJucyImages(item?.gallery, index) : showJucyCampervanDetail(item, index, 'gallery')} id={item?._id || showJucyCampervanDetail(item, index, '_id')} data={item} />
                  :
                  item?.campervan_type == 'spaceship'
                  ?
                  <ImageSliderDirect images={item?.images} id={item?._id || showJucyCampervanDetail(item, index, '_id')} data={item} />
                  :
                  <ImageSlider images={item?.images?.length != 0 ? item?.images : images1} id={item?._id || showJucyCampervanDetail(item, index, '_id')} />
                }

                <div className="absolute top-5 left-0 px-5 w-full flex items-center justify-between">
                  {item?.campervan_type == 'admin' || !item?.campervan_type
                    ?
                    <div className="bg-secondary rounded-lg py-2 lg:py-3 px-3 lg:px-[22px] text-sm/4  font-medium text-white">${item?.Id ? item?.DailyRate?.Value : campervanPrice(item, 'Perday')}/day</div>
                    :
                    <div></div>
                  }
                  <div className="size-8 rounded-full flex items-center justify-center" role="button" aria-label="add-to-wishlist" onClick={(e: any) => handleFavoriteUnFavorite(item?._id || item?.id || showJucyCampervanDetail(item, index, '_id'))}>
                    {findIsFavourite(item?.favourite_details || showJucyCampervanDetail(item, index, 'favourite_details')) ?
                      <IoMdHeart className="text-base text-primary size-6" /> : <CiHeart className="size-6 font-medium text-base text-secondary" />
                    }
                  </div>
                </div>
              </div>
              <div className="flex flex-col h-full gap-5 px-2 mt-2">
                <div className="flex-grow">
                  {item?.Id ?
                    <div className="flex justify-between max-sm:flex-wrap max-sm:gap-3  min-w-[60px]">
                      {showJucyCampervanDetail(item, index, 'highlightedFeatures')?.slice(0, 4)?.map((itm: any, index: number) => {
                        return <div className="flex flex-col items-center justify-center gap-1" key={index}>
                          {checkJucyAmenitiesImage(itm) && <img src={methodModel.campervanImage(checkJucyAmenitiesImage(itm))} width={24} height={24} className="size-6" />}
                          <p className="text-xs text-gray-light text-center min-w-[60px]">{itm || '--'}</p>
                        </div>
                      })}
                    </div>
                    :
                    <div className="flex justify-between  min-w-[60px] max-sm:flex-wrap max-sm:gap-3">
                      {item?.campervan_type == 'jucy' ?
                        <>
                          {item?.highlightedFeatures?.slice(0, 5)?.map((itm: any, index: number) => {
                            return <div className="flex flex-col items-center justify-center gap-1" key={index}>
                              {checkJucyAmenitiesImage(itm) && <img src={methodModel.campervanImage(checkJucyAmenitiesImage(itm))} width={24} height={24} className="size-6" />}
                              <p className="text-xs text-gray-light text-center min-w-[60px] ">{itm || '--'}</p>
                            </div>
                          })}
                        </>
                        :
                        <>
                          {item?.amenities_details?.slice(0, 5)?.map((itm: any, index: number) => {
                            return <>
                              {itm?.amenities_name &&
                                <div className="flex flex-col items-center justify-center gap-1" key={index}>
                                  {itm?.amenities_image ?
                                    <img src={methodModel.campervanImage(itm?.amenities_image)} alt="sleepBed" width={24} height={24} className="size-6" />
                                    : null}
                                  <p className="text-xs text-gray-light text-center min-w-[60px]">{itm?.amenities_name}</p>
                                </div>
                              }
                            </>
                          })}
                        </>}
                    </div>
                  }
                  <div className="flex flex-col-reverse sm:flex-row justify-between items-start gap-1.5 mt-2 lg:mt-4">
                    <div>
                      <h3 className="text-base 2xl:text-lg font-medium text-secondary line-clamp-1 capitalize">{item?.campervan_name || item?.Name}</h3>
                      <p className="text-sm lg:text-base font-normal text-gray-light my-2.5 lg:my-3 2xl:my-6 line-clamp-2 " dangerouslySetInnerHTML={{ __html: item?.description || item?.Description }}></p>
                    </div>
                    {item?.Availability ?
                      <div className="ml-auto whitespace-nowrap">
                        <span className="shrink-0 px-[22px] py-2.5 rounded-full flex items-center gap-1.5 text-secondary text-sm/4 bg-[#EEFBF4]">
                          <RxLightningBolt className="text-base size-4 text-secondary" />{item?.Availability || 'Instant Confirmation'}
                        </span>
                      </div>
                      : null}
                  </div>
                </div>
              </div>
              </div>
              <div className="px-2">
                <Link to={`/campervan/detail/${item?._id || showJucyCampervanDetail(item, index, '_id')}/${item?.campervan_type || showJucyCampervanDetail(item, index, 'campervan_type')}`} className="h-[46px] bg-white hover:bg-primary hover:text-white transition-all rounded-xl text-sm/7 2xl:text-base/7 font-medium border border-primary text-primary py-2 text-center mt-auto block">View Details</Link>
              </div>
            </div>
          </div>

          }
        </>
      ))}
    </Slider>
  )
}

export default CampervanSlider