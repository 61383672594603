import React, { useEffect, useState } from "react";
import PageLayout from "../../components/global/PageLayout";
import { IoIosSearch, IoMdTime } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { IoSpeedometerOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import { useSelector } from "react-redux";
import NewsLetter from "../../components/common/NewsLetter";
import Breadcrumb from "../../components/common/Breadcrumb";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { colourStyles } from "../../utils/selectStyle";
import DatePicker from "../../components/common/SearchBanner/DatePicker";
import { useDispatch } from "react-redux";
import { SEARCH_STATE } from "../../Redux/Action/Action";

interface DayDetailCardProps {
  item: any;
}

interface ItinerariesCardProps {
  item: any;
}

const Itineraries = () => {
  const { id } = useParams()
  const search = useSelector((state: any) => state.Reducer.search)
  const siteSettings = useSelector((state: any) => state.Reducer.siteSettings);
  const [detail, setDetail]: any = useState()
  const [suggestions, setSuggestions] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [filters, setFilters]: any = useState({
    pickUpLocation: '',
    dropOffLocation: '',
    start_date: null,
    end_date: null,
    isReturn: true,
    isDriverAge: false,
  });
  const [jucyLocations, setJucyLocations] = useState()

  useEffect(() => {
    getJucyLocations()
    getAllItineraries()
    if (id) getItinerariesDetail(id)
  }, [id])

  const getJucyLocations = () => {
    ApiClient.thirdPartyGet(`https://lanier.test.jucy.cloud/api/Sites/1.0/GetSites?country=all`).then(res => {
      if (res.ResponseType == 'Success') {
        setJucyLocations(res?.Data?.map((item: any) => {
          return ({ value: item?.SiteCode, label: item?.Name })
        }))
      }
    })
  }

  const handleSearchCampervans = () => {
    dispatch(SEARCH_STATE({ ...search, ...filters }))
    navigate('/campervans')
  }

  const getAllItineraries = () => {
    loader(true)
    ApiClient.get(`Itinerariers/all?status=active`).then((res: any) => {
      if (res.success) {
        if (!id) {
          let featured = []
          featured = res?.data?.filter((item: any) => item?.isFeatured)
          if (featured?.length != 0) getItinerariesDetail(featured?.[0]?._id || featured?.[0]?.id)
          else getItinerariesDetail(res?.data?.[0]?._id || res?.data?.[0]?.id)
        }
        let suggestList: any = []
        if (id) {
          suggestList?.filter((itm: any) => itm?._id != id && itm?.isSuggested)
        } else {
          suggestList = res?.data?.filter((item: any) => item?.isSuggested)
        }
        setSuggestions(suggestList)
      }
      loader(false)
    })
  }

  const getItinerariesDetail = (id: string) => {
    ApiClient.get(`Itinerariers?id=${id}`).then((res: any) => {
      if (res.success) {
        setDetail({ ...res?.data, thingstodo_details: res?.data?.get_thingstodo })
      }
    })
  }

  const DayDetailCard: React.FC<DayDetailCardProps> = ({ item }) => {
    return (
      <div className="w-full border border-[#787878] rounded-2xl p-4 2xl:p-8">
        <h2 className="text-xl 2xl:text-[32px] font-semibold text-secondary mb-3">
          Day {item?.day || '--'}
        </h2>
        {item?.image ?
          <img
            alt="city image"
            src={methodModel.noImg(item?.image)}
            className="w-full object-cover rounded-t-lg"
          />
          : null}
        <div className="flex flex-col sm:flex-row sm:items-center gap-3  my-3">
          <div className="flex items-center gap-2">
            <CiLocationOn className="size-6 text-[#787878]" />
            <p className="text-base/5 font-normal text-[#787878]">
              Distance : {item?.kms || '--'} km
            </p>
          </div>
          <div className="flex items-center gap-2">
            <IoMdTime className="size-6 text-[#787878]" />
            <p className="text-base/5 font-normal text-[#787878]">
              Travel Time : {calculateDuration(item?.travel_time)}
            </p>
          </div>
        </div>
        <h3 className="text-base 2xl:text-2xl/7 font-semibold text-secondary mb-3">
          {item?.cities?.map((itm: any, index: number) => {
            return <span> {itm} {item?.cities?.length == index + 1 ? null : '|'}</span>
          })}
        </h3>
        <p className="text-base font-normal text-secondary/70 mb-3 2xl:mb-6" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
        <h2 className="text-xl 2xl:text-[32px]/9 font-semibold text-secondary mb-3">
          Things to do :
        </h2>
        <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center gap-3 2xl:gap-6">
          {item?.images?.map((itm: any, index: number) => {
            return <div className="border border-[#ECECEC] rounded-lg">
              <img alt="city image" src={methodModel.noImg(itm?.images)} className="w-full h-[193px] object-cover rounded-t-lg" />
              <h4 className="text-sm text-center font-semibold text-secondary py-2 px-4">{itm?.cities}</h4>
            </div>
          })}
        </div>
        <div className="mt-3 list-disc text-sm font-medium text-secondary/85" dangerouslySetInnerHTML={{ __html: item?.things_to_do }}></div>
      </div>
    );
  };
  const ItinerariesCard: React.FC<ItinerariesCardProps> = ({ item }) => {
    return (
      <div className="w-full">
        <img
          alt={item?.image_alt}
          src={methodModel.noImg(item?.image)}
          className="w-full object-cover rounded-lg"
        />
        <div className="flex items-center gap-2 sm:gap-6 my-4">
          <div className="flex items-center gap-2">
            <SlCalender className="size-6 text-[#787878]" />
            <p className="text-base/5 font-normal text-[#787878]">
              {item?.start_day || '--'} to {item?.end_day || '--'} days
            </p>
          </div>
          <div className="flex items-center gap-2">
            <CiLocationOn className="size-6 text-[#787878]" />
            <p className="text-base/5 font-normal text-[#787878]">
              Distance : {item?.kms} km
            </p>
          </div>
        </div>
        <h3 className="text-sm 2xl:text-xl font-semibold text-secondary mb-3">
          {item?.cities?.map((itm: any, index: number) => {
            return <span> {itm} {item?.cities?.length == index + 1 ? null : '|'}</span>
          })}
        </h3>
        <p className="text-base font-normal text-secondary/70 mb-5" dangerouslySetInnerHTML={{ __html: item?.thingstodo_details?.[0]?.description }}></p>
        <button
          type="button"
          onClick={e => { navigate(`/itineraries/${item?._id || item?.id}`) }}
          className="w-full mt-3 py-3 h-[50px] px-[30px] rounded-xl bg-primary justify-center flex gap-1 items-center text-sm text-white hover:opacity-80"
        >
          View Itinerary
        </button>
      </div>
    );
  };

  const travelTime = (time: any) => {
    if (time) {
      const [hours, minutes] = time.split(':');
      if (minutes == '00') {
        return (`${hours} hr drive`)
      } else {
        return (`${hours} hr ${minutes} minutes drive`)
      }
    } else {
      return '--'
    }
  }

  const calculateDuration = (dateString: any) => {
    const date = new Date(dateString)
    const hours = moment(date).format('h')
    const minutes = moment(date).format('mm')
    if (minutes == '00') {
      return (`${hours} h`)
    } else {
      return (`${hours} h ${minutes} min`)
    }
  };

  const findDestination = (type: any) => {
    let destination = ''
    if (type == 'start') {
      destination = detail?.thingstodo_details?.[0]?.cities?.[0]
      return destination
    } else if (type == 'end') {
      const citiesLength = detail?.thingstodo_details?.[0]?.cities?.length
      destination = detail?.thingstodo_details?.[0]?.cities?.[citiesLength - 1]
      return destination
    }
    else {
      let waypoints: any = []
      detail?.thingstodo_details?.map((item: any, index: number) => {
        item?.cities?.map((itm: any, i: number) => {
          waypoints.push(itm)
        })
      })
      const uniqueSet: any = new Set(waypoints);
      let uniqueWaypoints: any = [...uniqueSet];
      uniqueWaypoints = String(uniqueWaypoints).replaceAll(',', '|')
      return uniqueWaypoints
    }
  }

  return (
    <PageLayout>
      <div className={`max-w-[1264px] w-full mx-auto px-4 lg:px-8`}>
        <div className="mt-5 sm:mt-10">
          <Breadcrumb links={[{ link: "/", name: "Home" }, { link: "/itineraries", name: "Brisbane" }]} currentPage={detail?.title} />
        </div>
        <h1 className="text-xl 2xl:text-5xl font-semibold text-secondary my-5  2xl:my-10">{detail?.title}</h1>
        <img alt={detail?.image_alt} src={methodModel.noImg(detail?.image)} className="w-full object-cover rounded-lg" />
        <div className="flex flex-col lg:flex-row max-lg:items-center justify-between gap-4 mt-6 2xl:mt-12">
          <div className="max-w-[1087px]">
            <h2 className="text-xl 2xl:text-[32px] font-semibold text-secondary mb-2 2xl:mb-4">{detail?.title}</h2>
            <div className="flex items-center gap-3 2xl:gap-6 mb-3  2xl:mb-6">
              <div className="flex  items-center gap-2">
                <IoMdTime className="size-4 sm:size-7 text-[#686868]" />
                <h6 className="whitespace-nowrap text-sm lg:text-xl font-medium text-[#686868]">{detail?.kms || '--'} km</h6>
              </div>
              <div className="flex items-center gap-2">
                <IoSpeedometerOutline className="size-4 sm:size-7 text-[#686868]" />
                <h6 className="whitespace-nowrap text-sm lg:text-xl font-medium text-[#686868]">{travelTime(detail?.travel_time)}</h6>
              </div>
              <div className="flex items-center gap-2">
                <CiCalendarDate className="size-4 sm:size-7 text-[#686868]" />
                <h6 className="whitespace-nowrap text-sm lg:text-xl font-medium text-[#686868]">{detail?.start_day || '--'} - {detail?.end_day || '--'} days</h6>
              </div>
            </div>
            <p className="text-base font-normal text-secondary/70" dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
          </div>
          <div className="border border-primary bg-white  p-3 xl:p-6 rounded-2xl max-w-[362px] w-full h-fit">
            <div className="grid grid-cols-12 gap-3 ">
              <div className="col-span-12 lg:col-span-12">
                <div className="flex flex-col gap-3">
                  <div className="flex-grow w-full">
                    <label htmlFor="" className="text-sm/4 font-medium text-secondary inline-block mb-2">Pickup Location</label>
                    <div className="w-full  ItinerariesSelect">
                      <Select
                        styles={colourStyles}
                        placeholder="Enter City, Airport or other location"
                        className="capitalize w-full cursor-pointer  min-w-fit   md:max-w-[313px]"
                        value={filters?.pickUpLocation}
                        onChange={(e) => setFilters((prev: any) => ({ ...prev, pickUpLocation: e }))}
                        options={jucyLocations}
                        isClearable
                        classNamePrefix="react"
                      />
                    </div>
                  </div>
                  {filters?.isReturn ? <></> : <>
                    <div className="w-full">
                      <label htmlFor="" className="text-sm/4 font-medium text-secondary inline-block mb-3">Dropoff Location</label>
                      <div className="w-full ItinerariesSelect">
                        <Select
                          styles={colourStyles}
                          placeholder="Enter City, Airport or other location"
                          className="capitalize w-full cursor-pointer min-w-fit md:max-w-[313px]"
                          value={filters?.dropOffLocation}
                          onChange={(e) => setFilters((prev: any) => ({ ...prev, dropOffLocation: e }))}
                          options={jucyLocations}
                          isClearable
                          classNamePrefix="react"
                        />
                      </div>
                    </div>
                  </>}
                </div>
                <div className="mt-3 flex gap-2 items-center">
                  <input type="checkbox"
                    checked={filters?.isReturn}
                    onChange={e => {
                      setFilters((prev: any) => ({ ...prev, isReturn: e.target.checked, dropOffLocation: '' }))
                    }}
                    name="sameLocation" id="sameLocation" className="shrink-0 text-primary size-4 border border-gray-400 rounded-sm cursor-pointer" />
                  <label htmlFor="sameLocation" className="text-sm/none text-[#6B6B6B] cursor-pointer">Return campervan to same location</label>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-12">
                <div className="flex gap-6  flex-wrap flex-col 2xl:flex-row">
                  <DatePicker
                    label="Pickup Date"
                    startDate={filters?.start_date}
                    endDate={filters?.end_date}
                    onChange={(date: any) => setFilters((prev: any) => ({ ...prev, start_date: date?.startDate, end_date: date?.endDate }))}
                  />
                  <DatePicker
                    label="Return Date"
                    showEndDate={true}
                    startDate={filters?.start_date}
                    endDate={filters?.end_date}
                    onChange={(date: any) => setFilters((prev: any) => ({ ...prev, start_date: date?.startDate, end_date: date?.endDate }))}
                  />
                </div>
              </div>
              <div className="col-span-12 flex items-center gap-2 mt-3">
                <input type="checkbox"
                  checked={filters?.isDriverAge}
                  onChange={e => {
                    setFilters({ ...filters, isDriverAge: e.target.checked })
                  }}
                  name="driverAge" id="driverAge" className="shrink-0 text-primary size-4 border border-gray-400 rounded-sm cursor-pointer" />
                <label htmlFor="driverAge" className="text-sm/none text-[#6B6B6B] cursor-pointer">
                  Driver Age: Minimum: <span className="underline underline-offset-4 decoration-primary"> 23 years </span>
                  Maximum: <span className="underline underline-offset-4 decoration-primary"> 70 years</span>
                </label>
              </div>
              <div className="col-span-12">
                <div className="flex justify-center">
                  <button type="button" onClick={() => handleSearchCampervans()} className="py-3 mt-4 px-[30px] max-w-[307px] w-full  rounded-xl bg-primary hover:opacity-80 transition-all flex gap-1 whitespace-nowrap items-center justify-center text-sm font-medium text-white">
                    <IoIosSearch className="text-lg text-white" /> Search Campervans
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:grid lg:grid-cols-2 gap-6 2xl:gap-12 my-6 2xl:my-12">
          <div className="w-full">
            <h3 className="text-xl 2xl:text-[32px] font-semibold text-secondary mb-3 2xl:mb-6">
              Itineraries Details
            </h3>
            <div className="border !overflow-x-auto scrollbar border-[#787878] bg-white w-full rounded-lg">
              <table className="w-full min-w-[500px]">
                <thead>
                  <tr className="border-b border-[#787878] text-sm 2xl:text-lg/5 font-semibold text-secondary text-start">
                    <th className="pl-[30px] py-[18px] min-w-[70px] text-start">
                      Day
                    </th>
                    <th className="pl-4 text-start">
                      Cities
                    </th>
                    <th className="pl-4 min-w-[60px] text-start">
                      Kms
                    </th>
                    <th className="pl-4 whitespace-nowrap text-start">
                      Travel Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detail?.thingstodo_details?.map((item: any, index: number) => (
                    <tr key={index} className="whitespace-nowrap text-sm sm:text-base font-normal text-[#787878] border-t-[0.7px] border-[#787878]">
                      <td className="pl-[30px] py-[26px]">Day {item?.day}</td>
                      <td>{item?.cities?.map((itm: any, i: number) => {
                        return <span> {itm} {item?.cities?.length == i + 1 ? null : '|'}</span>
                      })}</td>
                      <td className="text-center">{item?.kms} km</td>
                      <td className=" pl-5 md:pl-7">{calculateDuration(item?.travel_time)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {detail?.thingstodo_details?.length == 0 ? null :
            <div className="">
              <h3 className="text-xl 2xl:text-[32px] font-semibold text-secondary mb-3 2xl:mb-6">
                Map View
              </h3>
              <div className="h-full w-full !rounded-2xl">
                <iframe
                  src={`https://www.google.com/maps/embed/v1/directions?key=${siteSettings?.google_map_api_key}&origin=${findDestination('start')}&destination=${findDestination('end')}&waypoints=${findDestination('waypoints')}&mode=driving`}
                  className="w-full"
                  height="450px"
                  style={{ borderRadius: "8px 0px 0px 8px" }}
                  allowFullScreen={true}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          }
        </div>
        <div className="grid lg:grid-cols-2 gap-5 2xl:gap-12">
          {detail?.thingstodo_details?.map((item: any, index: number) => (
            <DayDetailCard key={index} item={item} />
          ))}
        </div>
        {suggestions?.length == 0 ? null :
          <>
            <h2 className="text-xl 2xl:text-[32px] font-semibold text-secondary mt-6 2xl:mt-12 mb-4">
              Our Suggested Itineraries
            </h2>
            <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-3 mb-6 2xl:mb-12">
              {suggestions.map((item: any, index: number) => (
                <ItinerariesCard key={index} item={item} />
              ))}
            </div>
          </>}
        {/* <div className="mt-6 lg:mt-28">
          <NewsLetter />
        </div> */}
      </div>
    </PageLayout>
  );
};

export default Itineraries;
