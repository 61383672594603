import React from "react";
import { FaCheck } from "react-icons/fa";

interface ReviewPreviewProps {
  tab: number;
  settab: React.Dispatch<React.SetStateAction<number>>;
}

const ReviewPreview: React.FC<ReviewPreviewProps> = ({ settab }) => {
  return (
    <div className={`max-w-[1264px] w-full mx-auto px-4 lg:px-8 mt-6 2xl:mt-12`}>
      <div className="flex flex-col sm:flex-row items-center gap-4 2xl:gap-8 mt-6 2xl:mt-12 mb-7 pb-36">
        <button
          type="button" 
          onClick={() => settab(7)}
          className="inline-block h-10 sm:h-11 w-[298px] text-white text-sm lg:text-base 2xl:text-lg/5 font-medium bg-primary rounded-xl hover:opacity-80"
        >
          Submit listing
        </button>
      </div>
    </div>
  );
};

export default ReviewPreview;
