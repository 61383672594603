import { useEffect, useState } from "react"
import ApiClient from "../../methods/api/apiClient"
import methodModel from "../../methods/methods"
import { Tab } from '@headlessui/react'
import { useSelector } from "react-redux"

const FeaturesFacilities = ({ detail }) => {
  const jucyAmenitiesImages = useSelector((state) => state.Reducer.jucyImages)
  const [inclusions, setinclusions] = useState([])
  const [campervanDetails, setcampervanDetails] = useState([])
  const [campervanFeatures, setcampervanFeatures] = useState([])
  const [livingFeatures, setlivingFeatures] = useState([])
  const [passengers, setpassengers] = useState([])
  const [tankCapacity, settankCapacity] = useState([])
  const [entertainment, setentertainment] = useState([])
  const [activeTab, setactiveTab] = useState(0)

  useEffect(() => {
    getAllInclusions()
    getAllCampervanDetails()
    getAllCampervanFeatures()
    getAllLivingFeatures()
    getAllPassengers()
    getAllTankCapacity()
    getAllEntertainments()
  }, [])

  const getAllInclusions = () => {
    ApiClient.get(`all/CampervanSpecifications/options?status=active&slug=Included`).then(res => {
      if (res.success) {
        setinclusions(res?.data?.filter((item, index) => {
          if (item?.dataFormat === 'checkbox') return item
        }))
      }
    })
  }
  const getAllCampervanDetails = () => {
    ApiClient.get(`all/CampervanSpecifications/options?status=&slug=vehicle`).then(res => {
      if (res.success) {
        setcampervanDetails(res?.data)
      }
    })
  }
  const getAllCampervanFeatures = () => {
    ApiClient.get(`all/CampervanSpecifications/options?status=active&slug=other_vehicle`).then(res => {
      if (res.success) {
        setcampervanFeatures(res?.data)
      }
    })
  }
  const getAllLivingFeatures = () => {
    ApiClient.get(`all/CampervanSpecifications/options?status=&slug=Living`).then(res => {
      if (res.success) {
        setlivingFeatures(res?.data)
      }
    })
  }
  const getAllPassengers = () => {
    ApiClient.get(`all/CampervanSpecifications/options?status=&slug=Passenger`).then(res => {
      if (res.success) {
        setpassengers(res?.data)
      }
    })
  }
  const getAllTankCapacity = () => {
    ApiClient.get(`all/CampervanSpecifications/options?status=&slug=Tank_capacities`).then(res => {
      if (res.success) {
        settankCapacity(res?.data)
      }
    })
  }
  const getAllEntertainments = () => {
    ApiClient.get(`all/CampervanSpecifications/options?status=&slug=Entertainment`).then(res => {
      if (res.success) {
        setentertainment(res?.data)
      }
    })
  }

  const showDropdownInputFormat = (item, key) => {
    let data = '--'
    detail?.[key]?.filter((itm) => {
      if (itm?.label === item?._id || itm?.label === item?.id) data = itm?.value || '--'
    })
    return data
  }

  const checkBeds = (type = 'tab') => {
    let value = Object?.keys(detail?.specifications || {})?.some((item) => item?.includes('Bed'))
    if (type == 'extraItems') {
      if (value && activeTab === 7) return "bg-[#F1F4FF]"
      else if (!value && activeTab === 6) return "bg-[#F1F4FF]"
      else return ""
    }
    else if (type == 'insurance') {
      if (value && activeTab === 8) return "bg-[#F1F4FF]"
      else if (!value && activeTab === 7) return "bg-[#F1F4FF]"
      else return ""
    }
    else return value
  }

  // Check the jucy image based on the name if exists return the image
  const checkJucyAmenitiesImage = (name) => {
    let data = jucyAmenitiesImages && jucyAmenitiesImages?.find((item, index) => {
      if (item?.name == name) {
        return item
      }
    })
    return data?.image
  }
  
  return (
    <>
      <div className="icons_ilustion mb-3 lg:mb-4">
        <div className="flex flex-col border-b border-gray-border pb-3 lg:pb-4">
          <h2 className="font-semibold text-secondary mb-2 text-lg lg:text-xl 2xl:text-2xl">Inclusions</h2>
            <div className="flex flex-col md:flex-row gap-y-3 justify-between">
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-x-5 2xl:gap-x-10 gap-y-4">
                {detail?.campervan_type === 'jucy' ?
                  <>
                    {detail?.includedWith && detail?.includedWith?.map((item, idx) => (
                      <div key={idx} className={`flex items-center gap-x-3 capitalize w-full ${(idx + 1) % 3 === 0 ? '' : ''}`}>
                        <img src={`/assets/img/front/${item?.icon === 'check' ? 'done' : 'close'}.svg`} alt="icon" className="size-5" />
                        <p className="text-sm lg:text-base font-normal text-secondary">{item?.title}</p>
                      </div>
                    ))}
                  </>
                  :
                  <>
                    {inclusions && inclusions?.map((item, idx) => (
                      <div key={idx} className={`flex items-center gap-x-3 capitalize w-full ${(idx + 1) % 3 === 0 ? 'md:border-r-0' : ''}`} >
                        <img src={`/assets/img/front/${detail?.IncludedWithStandardrate?.includes(item?._id || item?.id) ? 'done' : 'close'}.svg`} alt="" className="size-5" />
                        <p className="text-sm lg:text-base font-normal text-secondary">{item?.name}</p>
                      </div>
                    ))}
                  </>
                }
              </div>
            </div>
        </div>
      </div>
      {detail?.amenities_details?.length != 0 &&
        <div className="icons_ilustion mb-4  2xl:mb-12">
            <h2 className="font-semibold mb-2 text-secondary text-lg lg:text-xl 2xl:text-2xl">Amenities</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 lg:gap-3 2xl:gap-y-5 2xl:gap-x-8">
              {detail?.amenities_details?.map((item, idx) => (
                <div key={idx} className={`flex items-center gap-x-3 capitalize w-full ${(idx + 1) % 3 === 0 ? 'md:border-r-0' : ''}`} >
                  {detail?.campervan_type == 'jucy' ?
                    <>
                      {checkJucyAmenitiesImage(item?.name) && <img src={methodModel.campervanImage(checkJucyAmenitiesImage(item?.name))} width="20px" />}
                    </>
                    :
                    <>
                      {item?.image && <img src={methodModel.campervanImage(item?.image)} alt={item?.image_alt_name} width="25px" />}
                    </>}
                  <p className="text-sm lg:text-base text-secondary font-normal">{item?.name}</p>
                </div>
              ))}
            </div>
        </div>
      }
      <div className="icons_ilustion mb-4  2xl:mb-12">
          <h2 className="font-semibold text-secondary mb-2 text-lg lg:text-xl 2xl:text-2xl">Other Details</h2>
            <div className="border border-gray-border rounded-xl overflow-hidden">
              <Tab.Group selectedIndex={activeTab} onChange={setactiveTab}>
                <div className="grid grid-cols-12">
                  <div className="col-span-12 md:col-span-5">
                    <div className="flex flex-col max-md:border-b md:border-r border-gray-border h-full">
                      <Tab.List>
                        <Tab className={`iconwithtext flex items-center gap-x-3 text-gray-light w-full focus:outline-0 p-2.5 lg:px-6 lg:py-[15px] border-b border-gray-border ${activeTab === 0 ? 'bg-[#E6EBFF] text-primary ' : ''} `}>
                            <img src="/assets/img/front/o1.svg" alt="" width={20} height={20} className={`size-5 shrink-0 `} />
                            <p className="text-sm lg:text-base font-normal text-left"> Campervan Details</p>
                        </Tab>
                        <Tab className={`iconwithtext flex items-center gap-x-3 text-gray-light w-full focus:outline-0 p-2.5 lg:px-6 lg:py-[15px] border-b border-gray-border ${activeTab === 1 ? 'bg-[#E6EBFF] text-primary' : ''} `}>
                            <img src="/assets/img/front/o2.svg" alt="" width={20} height={20} className="size-5 shrink-0" />
                            <p className="text-sm lg:text-base font-normal text-left"> Campervan Features</p>
                        </Tab>
                        <Tab className={`iconwithtext flex items-center gap-x-3 text-gray-light w-full focus:outline-0 p-2.5 lg:px-6 lg:py-[15px] border-b border-gray-border ${activeTab === 2 ? 'bg-[#E6EBFF] text-primary' : ''} `}>
                            <img src="/assets/img/front/o3.svg" alt="" width={20} height={20} className="size-5 shrink-0" />
                            <p className="text-sm lg:text-base font-normal text-left"> Living Features</p>
                        </Tab>
                        <Tab className={`iconwithtext flex items-center gap-x-3 text-gray-light w-full focus:outline-0 p-2.5 lg:px-6 lg:py-[15px] border-b border-gray-border ${activeTab === 3 ? 'bg-[#E6EBFF] text-primary' : ''} `}>
                            <img src="/assets/img/front/o4.svg" alt="" width={20} height={20} className="size-5 shrink-0" />
                            <p className="text-sm lg:text-base font-normal text-left"> Passenger</p>
                        </Tab>
                        <Tab className={`iconwithtext flex items-center gap-x-3 text-gray-light w-full focus:outline-0 p-2.5 lg:px-6 lg:py-[15px] border-b border-gray-border ${activeTab === 4 ? 'bg-[#E6EBFF] text-primary' : ''} `}>
                            <img src="/assets/img/front/o5.svg" alt="" width={20} height={20} className="size-5 shrink-0" />
                            <p className="text-sm lg:text-base font-normal text-left"> Tank Capacity</p>
                        </Tab>
                        <Tab className={`iconwithtext flex items-center gap-x-3 text-gray-light w-full focus:outline-0 p-2.5 lg:px-6 lg:py-[15px] border-b border-gray-border ${activeTab === 5 ? 'bg-[#E6EBFF] text-primary' : ''} `}>
                            <img src="/assets/img/front/o6.svg" alt="" width={20} height={20} className="size-5 shrink-0" />
                            <p className="text-sm lg:text-base font-normal text-left"> Entertainment</p>
                        </Tab>
                        {checkBeds() &&
                          <Tab className={`iconwithtext flex items-center gap-x-3 text-gray-light w-full focus:outline-0 p-2.5 lg:px-6 lg:py-[15px] border-b border-gray-border ${activeTab === 6 ? 'bg-[#E6EBFF] text-primary' : ''} `}>
                              <img src="/assets/img/front/o7.svg" alt="" width={20} height={20} className="size-5 shrink-0" />
                              <p className="text-sm lg:text-base font-normal text-left"> Beds</p>
                          </Tab>
                        }
                        {detail?.campervan_type == 'jucy' || detail?.campervan_type == "spaceship" ?
                          <>
                            <Tab className={`iconwithtext flex items-center gap-x-3 text-gray-light w-full focus:outline-0 p-2.5 lg:px-6 lg:py-[15px] border-b border-gray-border ${checkBeds('extraItems')} ${activeTab === 7 ? 'bg-[#E6EBFF] text-primary' : ''} `}>
                                <img src="/assets/img/front/o2.svg" alt="" width={20} height={20} className="size-5 shrink-0" />
                                <p className="text-sm lg:text-base font-normal text-left"> Extra Items</p>
                            </Tab>
                            <Tab className={`iconwithtext flex items-center gap-x-3 text-gray-light w-full focus:outline-0 p-2.5 lg:px-6 lg:py-[15px]  border-gray-border ${checkBeds('insurance')} ${activeTab === 8 ? 'bg-[#E6EBFF] text-primary' : ''} `}>
                                <img src="/assets/img/front/o3.svg" alt="" width={20} height={20} className="size-5 shrink-0" />
                                <p className="text-sm lg:text-base font-normal text-left"> Insurance</p>
                            </Tab>
                          </>
                          : null}
                      </Tab.List>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-7">
                    <div className="flex items-start justify-center h-full py-5">
                      <div className="border border-gray-border bg-white rounded-xl overflow-hidden w-[95%] mx-auto">
                        <Tab.Panels>
                          <Tab.Panel className="">
                            {detail?.campervan_type == 'jucy' ?
                              // For Jucy Details
                              <>
                                {Object.keys(detail?.specifications)?.map((item, index) => {
                                  return <>
                                    {item?.includes('Vehicle') || item?.includes('Engine') || item?.includes('Transmission') || item?.includes('Fuel') || item?.includes('Fuel type') || item?.includes('Fuel usage') || item?.includes('Length') || item?.includes('Width') || item?.includes('Height') || item?.includes('Interior') ?
                                      <div className="flex justify-between items-center p-3 lg:px-8 lg:py-4  odd:bg-[#F6F7FB] gap-4 " key={index}>
                                        <p className="text-gray-light text-sm">{item?.replaceAll(':', '')}</p>
                                        <p className="text-secondary text-base text-end capitalize">{detail?.specifications?.[item] || '--'} </p>
                                      </div>
                                      : null}
                                  </>
                                })}
                              </>
                              :
                              // For Peer to Peer Details
                              <>
                                <div className="flex justify-between items-center p-3 lg:px-8 lg:py-4  odd:bg-[#F6F7FB]  gap-4 ">
                                  <p className="text-gray-light text-sm">Vehicle Age</p>
                                  <p className="text-secondary text-base text-end">{detail?.vehicleStartAge ? `${detail?.vehicleStartAge}-${detail?.vehicleEndAge}` : '--'} </p>
                                </div>
                                <div className="flex justify-between items-center p-3 lg:px-8 lg:py-4  odd:bg-[#F6F7FB]  gap-4">
                                  <p className="text-gray-light text-sm">Make/Modal</p>
                                  <p className="text-secondary text-base text-end">{detail?.make_model || '--'}</p>
                                </div>
                                {campervanDetails && campervanDetails?.map((item, idx) => (
                                  <div key={idx} className="flex justify-between items-center p-3 lg:px-8 lg:py-4  odd:bg-[#F6F7FB]  gap-4">
                                    <p className="text-gray-light text-sm capitalize">{item?.name}</p>
                                    {item?.dataFormat === 'checkbox' ?
                                      <img src={`/assets/img/front/${detail?.vehicleSpecifications?.includes(item?._id || item?.id) ? 'done' : 'close'}.svg`} alt="" className="shrink-0 h-4" />
                                      :
                                      <p className="text-secondary text-base text-end">{showDropdownInputFormat(item, 'vehicleSpecificationsDropdownInputKey')}</p>
                                    }
                                  </div>
                                ))}
                              </>
                            }
                          </Tab.Panel>
                          <Tab.Panel className="">
                            {detail?.campervan_type == 'jucy' ?
                              <>
                                {detail?.highlightedFeatures?.map((item, index) => {
                                  return <div key={index} className={`flex justify-between items-center p-3 lg:px-8 lg:py-4 odd:bg-[#F6F7FB]  gap-4  ${index === 0 ? '' : ''}`}>
                                    <p className="text-gray-light text-sm capitalize">{item}</p>
                                    <img src={`/assets/img/front/done.svg`} alt="" className="shrink-0 h-4" />
                                  </div>
                                })}
                                <div className="flex justify-between items-center p-3 lg:px-8 lg:py-4  odd:bg-[#F6F7FB] gap-4 ">
                                  <p className="text-gray-light text-sm">Power supply</p>
                                  <p className="text-secondary text-base text-end">{detail?.specifications?.['Power supply'] || detail?.specifications?.['Power supply '] || detail?.specifications?.['Power supply:'] || '--'} </p>
                                </div>
                                {Object.keys(detail?.specifications)?.map((item, index) => {
                                  return <>
                                    {item?.includes('Luggage') ?
                                      <div className="flex justify-between items-center  odd:bg-[#F6F7FB] p-3 lg:px-8 lg:py-4 gap-4 " key={index}>
                                        <p className="text-gray-light text-sm">{item?.replaceAll(':', '')}</p>
                                        <p className="text-secondary text-base text-end capitalize">{detail?.specifications?.[item] || '--'} </p>
                                      </div>
                                      : null}
                                  </>
                                })}
                              </>
                              :
                              <>
                                {campervanFeatures && campervanFeatures?.map((item, idx) => (
                                  <div key={idx} className={`flex justify-between items-center p-3 lg:px-8 lg:py-4  odd:bg-[#F6F7FB]  gap-4  ${idx === 0 ? '' : ''}`}>
                                    <p className="text-gray-light text-sm capitalize">{item?.name}</p>
                                    {item?.dataFormat === 'checkbox' ?
                                      <img src={`/assets/img/front/${detail?.othervehiclefeatures?.includes(item?._id || item?.id) ? 'done' : 'close'}.svg`} alt="" className="shrink-0 h-4" />
                                      :
                                      <p className="text-secondary text-sm text-end">{showDropdownInputFormat(item, 'otherVehicalFeaturesDropdownInputKey')}</p>
                                    }
                                  </div>
                                ))}
                              </>
                            }
                          </Tab.Panel>
                          <Tab.Panel className="">
                            {livingFeatures && livingFeatures?.map((item, idx) => (
                              <div key={idx} className={`flex justify-between items-center p-3 lg:px-8 lg:py-4 odd:bg-[#F6F7FB]  gap-4  ${idx === 0 ? '' : ''}`}>
                                <p className="text-gray-light text-sm capitalize">{item?.name}</p>
                                {item?.dataFormat === 'checkbox' ?
                                  <img src={`/assets/img/front/${detail?.livingFeatures?.includes(item?._id || item?.id) ? 'done' : 'close'}.svg`} alt="" className="shrink-0 h-4" />
                                  :
                                  <p className="text-secondary text-base text-end">{showDropdownInputFormat(item, 'livingFeaturesDropdownInputKey')}</p>
                                }
                              </div>
                            ))}
                          </Tab.Panel>
                          <Tab.Panel className="">
                            {detail?.campervan_type == 'jucy' ?
                              <>
                                {Object.keys(detail?.specifications)?.map((item, index) => {
                                  return <>
                                    {item?.includes('Body style') || item?.includes('Seat') || item?.includes('Sleep') || item?.includes('Child') ?
                                      <div className="flex justify-between items-center  p-3 lg:px-8 lg:py-4  odd:bg-[#F6F7FB]  gap-4 " key={index}>
                                        <p className="text-gray-light text-sm">{item?.replaceAll(':', '')}</p>
                                        <p className="text-secondary text-base text-end capitalize">{detail?.specifications?.[item] || '--'} </p>
                                      </div>
                                      : null}
                                  </>
                                })}
                              </>
                              :
                              <>
                                {passengers && passengers?.map((item, idx) => (
                                  <div key={idx} className={`flex justify-between items-center  p-3 lg:px-8 lg:py-4 odd:bg-[#F6F7FB]  gap-4  ${idx === 0 ? '' : ''}`}>
                                    <p className="text-gray-light text-sm capitalize">{item?.name}</p>
                                    {item?.dataFormat === 'checkbox' ?
                                      <img src={`/assets/img/front/${detail?.passengers?.includes(item?._id || item?.id) ? 'done' : 'close'}.svg`} alt="" className="shrink-0 h-4" />
                                      :
                                      <p className="text-secondary text-base text-end">{showDropdownInputFormat(item, 'passengersDropdownInputKey')}</p>
                                    }
                                  </div>
                                ))}
                              </>
                            }
                          </Tab.Panel>
                          <Tab.Panel className="">
                            {detail?.campervan_type == 'jucy' ?
                              <>
                                {Object.keys(detail?.specifications)?.map((item, index) => {
                                  return <>
                                    {item?.includes('Fuel tank') ?
                                      <div className="flex justify-between items-center  odd:bg-[#F6F7FB] p-3 lg:px-8 lg:py-4  gap-4 " key={index}>
                                        <p className="text-gray-light text-sm">{item?.replaceAll(':', '')}</p>
                                        <p className="text-secondary text-base text-end capitalize">{detail?.specifications?.[item] || '--'} </p>
                                      </div>
                                      : null}
                                  </>
                                })}
                              </>
                              :
                              <>
                                {tankCapacity && tankCapacity?.map((item, idx) => (
                                  <div key={idx} className={`flex justify-between items-center  odd:bg-[#F6F7FB] p-3 lg:px-6 lg:py-4  gap-4  ${idx === 0 ? '' : ''}`}>
                                    <p className="text-gray-light text-sm capitalize">{item?.name}</p>
                                    {item?.dataFormat === 'checkbox' ?
                                      <img src={`/assets/img/front/${detail?.tankCapacity?.includes(item?._id || item?.id) ? 'done' : 'close'}.svg`} alt="" className="shrink-0 h-4" />
                                      :
                                      <p className="text-secondary text-base text-end">{showDropdownInputFormat(item, 'tankCapacityDropdownInputKey')}</p>
                                    }
                                  </div>
                                ))}
                              </>
                            }
                          </Tab.Panel>
                          <Tab.Panel className="">
                            {entertainment && entertainment?.map((item, idx) => (
                              <div key={idx} className={`flex justify-between items-center  odd:bg-[#F6F7FB] p-3 lg:px-6 lg:py-4  gap-4  ${idx === 0 ? '' : ''}`}>
                                <p className="text-gray-light text-sm capitalize">{item?.name}</p>
                                {item?.dataFormat === 'checkbox' ?
                                  <img src={`/assets/img/front/${detail?.entertainment?.includes(item?._id || item?.id) ? 'done' : 'close'}.svg`} alt="" className="shrink-0 h-4" />
                                  :
                                  <p className="text-secondary text-base text-end">{showDropdownInputFormat(item, 'entertainmentDropdownInputKey')}</p>
                                }
                              </div>
                            ))}
                          </Tab.Panel>
                          {Object?.keys(detail?.specifications || {})?.some((item) => item?.includes('Bed')) ?
                            <Tab.Panel className="">
                              {detail?.campervan_type == 'jucy' ?
                                <>
                                  {Object.keys(detail?.specifications)?.map((item, index) => {
                                    return <>
                                      {item?.includes('Bed') ?
                                        <div className="flex justify-between items-center  odd:bg-[#F6F7FB] p-3 lg:px-8 lg:py-4  gap-4 " key={index}>
                                          <p className="text-gray-light text-sm">{item?.replaceAll(':', '')}</p>
                                          <p className="text-secondary text-base text-end">{detail?.specifications?.[item] || '--'} </p>
                                        </div>
                                        : null}
                                    </>
                                  })}
                                </>
                                :
                                <div className="flex justify-between items-center p-3 lg:px-8 lg:py-4  odd:bg-[#F6F7FB] gap-4">
                                  <p className="text-gray-light text-sm">{detail?.beds || '--'}</p>
                                </div>
                              }
                            </Tab.Panel>
                            : null}
                          {detail?.campervan_type == 'jucy' || detail?.campervan_type == "spaceship" ?
                            <>
                              <Tab.Panel className="">
                                {detail?.extraItems?.length == 0 ?
                                  <>
                                    <div className="flex justify-between items-center  odd:bg-[#F6F7FB] p-3 lg:px-8 lg:py-4  gap-4">
                                      <p className="text-gray-light text-sm">--</p>
                                    </div>
                                  </>
                                  :
                                  <>
                                    {detail?.extraItems?.map((item, index) => {
                                      return <div className="flex justify-between items-center  odd:bg-[#F6F7FB] p-3 lg:px-8 lg:py-4  gap-4" key={index}>
                                        <p className="text-gray-light text-sm">{item || '--'}</p>
                                      </div>
                                    })}
                                  </>
                                }
                              </Tab.Panel>
                              <Tab.Panel className="">
                                {detail?.campervan_type == "jucy" ?
                                  <div className="scroll-auto flex justify-between items-center  odd:bg-[#F6F7FB] p-3 lg:px-8 lg:py-4  gap-4 overflow-auto ">
                                    <div className="w-full">
                                      <div className="table-responsive">
                                        <table className="w-full border-collapse table-auto	">
                                          <thead className="w-full">
                                            <tr className="bg-primary text-white mb-2 rounded-lg">
                                              <th className="w-44 py-2 rounded-tl-lg rounded-bl-lg"></th>
                                              <th className=" py-2 text-white text-base font-normal px-2">Basic</th>
                                              <th className=" py-2 text-white text-base font-normal px-2">Full</th>
                                              <th className=" py-2 text-white text-base font-normal px-2 rounded-tr-lg rounded-br-lg">Standard</th>
                                            </tr>
                                          </thead>
                                          <tbody className="">
                                            {detail?.excessReductionFeatures?.map((item, index) => {
                                              return <>
                                                <tr className="odd:bg-gray-100 even:bg-white">
                                                  <td className="w-44 px-4 text-sm text-gray-light text-left">{item?.name}</td>
                                                  <td className="px-4 py-2 text-base text-secondary font-normal text-center">{item?.basic == true || item?.basic == false ? <img className="mx-auto h-4" src={`/assets/img/front/${item?.basic ? 'done' : 'close'}.svg`} /> : item?.basic}</td>
                                                  <td className="px-4 py-2 text-base text-secondary font-normal text-center">{item?.full == true || item?.full == false ? <img className="mx-auto h-4" src={`/assets/img/front/${item?.full ? 'done' : 'close'}.svg`} /> : item?.full}</td>
                                                  <td className="px-4 py-2 text-base text-secondary font-normal text-center">{item?.standard == true || item?.standard == false ? <img className="mx-auto h-4" src={`/assets/img/front/${item?.standard ? 'done' : 'close'}.svg`} /> : item?.standard}</td>
                                                </tr>
                                              </>
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <>
                                    {detail?.insurance_details?.map((item, index) => {
                                      return <div className="flex justify-between items-center odd:bg-[#F6F7FB] p-3 lg:px-8 lg:py-4 gap-4" key={index}>
                                        <p className="text-secondary text-base">{item?.company_name}</p>
                                      </div>
                                    })}
                                  </>
                                }
                              </Tab.Panel>
                            </>
                            : null}
                        </Tab.Panels>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Group>
            </div>
      </div>
    </>
  )
}

export default FeaturesFacilities