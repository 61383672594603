import Pagination from "react-js-pagination";
import ImageSliderDirect from "../../../components/common/ImageSliderDirect";
import ImageSlider from "../../../components/common/ImageSlider";
import { IoIosArrowRoundBack, IoIosArrowRoundForward, IoMdHeart } from "react-icons/io";
import { CiHeart } from "react-icons/ci";
import StarRatings from "react-star-ratings";
import { SEARCH_STATE } from "../../../Redux/Action/Action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import methodModel from "../../../methods/methods";
import { useSelector } from "react-redux";
import ApiClient from "../../../methods/api/apiClient";
import moment from "moment";


export const Campervans = (
  { campervans,
    findAlternativeLocationsCampervan,
    findCheapestCampervan,
    showJucyImages,
    showJucyCampervanDetail,
    totalCampervans,
    filter,
    setfilter,
    getcampervan,
    currentCampervanLowestPrice
  }
) => {
  const jucyAmenitiesImages = useSelector((state) => state.Reducer.jucyImages)
  const search = useSelector((state) => state.Reducer.search)
  const user = useSelector((state) => state.User)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const images1 = [
    "assets/img/front/camper1.svg",
    "assets/img/front/camper2.svg",
  ]

  const handleAction = (item, data, key) => {
    if (data?.vehiclecategoryid) {
      dispatch(SEARCH_STATE({ ...search, vehiclecategoryid: data?.vehiclecategoryid }))
    }
    if (key == 'view details') {
      if (item) navigate(`/campervan/detail/${item}/${data?.campervan_type}`)
      else return
    }
  }

  const checkFilters = () => {
    if (filter?.pickUpLocation?.label && filter?.start_date && filter?.end_date) return true
    else return false
  }

  const findIsFavourite = (favoriteDetails) => {
    let value = []
    value = favoriteDetails?.filter(item => {
      if (item?.fav_user_id == user?.id) {
        return item
      } else {
        return false
      }
    })
    return value?.[0]?.isFavourite
  }

  // Handle redirection direct to the cheapest campervan detail page for alternative dates only
  const hanldeAlternativeDates = (startDate, endDate, id, type) => {
    startDate = moment(startDate).format('YYYY-M-DD')
    endDate = moment(endDate).format('YYYY-M-DD')
    let params = { ...search, start_date: startDate, end_date: endDate }
    dispatch(SEARCH_STATE(params))
    if (id) navigate(`/campervan/detail/${id}/${type}`)
  }

  // Handle redirection direct to the cheapest campervan detail page for alternative locations only
  const handleAlternativeLocations = (pickuplocation, dropofflocation, id, type) => {
    let params = { ...search, pickUpLocation: pickuplocation, dropOffLocation: dropofflocation }
    dispatch(SEARCH_STATE(params))
    if (id) navigate(`/campervan/detail/${id}/${type}`)
  }

  // Redirect user to the booking page
  const handleBookNow = (id, data) => {
    dispatch(SEARCH_STATE({ ...search, vehiclecategoryid: data?.vehiclecategoryid || "" }))
    navigate(`/bookingcampervan/${id}`)
  }

  const handlePagination = (e) => {
    setfilter(prev => ({ ...prev, page: e }))
    getcampervan({ page: e })
  }

  // Handle functionality to add campervan is wishlist or remove
  const handleFavoriteUnFavorite = (id) => {
    let token = localStorage.getItem('token')
    if (!token) navigate('/login')
    ApiClient.post(`favourite`, { campervan_id: id }).then(res => {
      if (res.success) {
        getcampervan()
      }
    })
  }

  // Check the jucy image based on the name if exists return the image
  const checkJucyAmenitiesImage = (name) => {
    let data = jucyAmenitiesImages && jucyAmenitiesImages?.find((item, index) => {
      if (item?.name == name) {
        return item
      }
    })
    return data?.image
  }

  // Return the per day campervan price according to total days and total campervan price behalf of type key
  const campervanPrice = (item,type) => {
    let price = Number(item?.defaultRate || 0) + Number(item?.serviceFees || 0) + Number(item?.security_deposite || 0)
    if (type === 'Perday') {
      let days = item?.campervan_type == "spaceship" ? item?.numberofdays : item?.RentalDays
      price /= days === 0 ? 1 : days
    }
    return parseFloat(price || 0).toFixed(2)
  }

  // It return the total number of days from the start and end date
  const calculateDays = (startDate, endDate) => {
    if (endDate < startDate) {
      return 0;
    }
    const diffInMilliseconds = Math.abs(endDate - startDate);
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
    return Math.ceil(diffInDays);
  };

  return (
    <>
      <div className="flex-1 w-full">
        {/* Locations */}
        <div className="overflow-x-auto " id="locationDiv">
          {/* Alternate Dates & Location */}
          {(campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0) != 0 || (campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0) != 0 ?
            <div className="border rounded-2xl border-gray-border overflow-x-auto scrollbar mb-3 lg:mb-6">
              <table className="table w-full table-fixed">
                <thead>
                  <tr>
                    <th className="font-medium p-2 text-left w-[150px]">
                      <p className="flex text-secondary">
                        {/* <GiSurferVan /> */}
                        <span className="text-base font-normal text-secondary ">Alternate Options</span>
                      </p>
                    </th>
                    {(campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0) != 0 || findAlternativeLocationsCampervan('price', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value) ?
                      <th className="relative border-l font-medium p-3 2xl:p-5 text-center">
                        <img src="/assets/img/image55.svg" alt="image55.png" className="w-full object-contain h-10" />
                        <p className="text-gray-light text-xs font-bold"> AUD ${campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0}</p>
                        {currentCampervanLowestPrice.current > findCheapestCampervan('price', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0) && <span className="absolute rounded bg-[#FFA500] text-white font-normal text-[10px] w-[51px] h-4 top-2 right-2 appearance-none flex items-center justify-center">Cheapest</span>}
                      </th> : null
                    }
                    {(campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0) != 0 || findAlternativeLocationsCampervan('price', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate) ?
                      <th className="relative border-l font-medium p-3 2xl:p-5 text-center">
                        <img src="/assets/img/image22.svg" alt="image55.png" className="w-full object-contain h-10" />
                        <p className="text-gray-light text-xs font-bold"> AUD ${campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0}</p>
                        {currentCampervanLowestPrice.current > findCheapestCampervan('price', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0) && <span className="absolute rounded bg-[#FFA500] text-white font-normal text-[10px] w-[51px] h-4 top-2 right-2 appearance-none flex items-center justify-center">Cheapest</span>}
                      </th> : null
                    }
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-t font-normal p-2 text-left">
                      <p className="flex text-secondary">
                        {/* <img src="assets/img/front/calender.svg" className="mr-2 size-6 shrink-0" alt="" width={24} height={24} /> */}
                        <span className="text-secondary text-base ">
                          Current Search
                        </span>
                      </p>
                    </td>
                    {(campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0) != 0 && findAlternativeLocationsCampervan('price', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value) ?
                      <td className="border-b-0 border-r-0 border p-3 lg:p-5 text-center hover:bg-[#FFF0D3] transition-all cursor-pointer" onClick={e => hanldeAlternativeDates(findCheapestCampervan('startDate', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0), findCheapestCampervan('endDate', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0), findCheapestCampervan('id', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0), 'jucy')}>
                        <p className="text-gray-light text-xs">{findCheapestCampervan('startDate', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0)}</p>
                        <p className="text-secondary text-base font-bold">${findCheapestCampervan('price', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0)}</p>
                      </td> :
                      <>{findAlternativeLocationsCampervan('price', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value) ?
                        <td className="text-center">
                          <p>--</p>
                        </td>
                        : null}
                      </>
                    }
                    {(campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0) != 0 && findAlternativeLocationsCampervan('price', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate) ?
                      <td className="border-b-0 border-r-0 border p-3 lg:p-5 text-center hover:bg-[#FFF0D3] transition-all cursor-pointer" onClick={e => hanldeAlternativeDates(findCheapestCampervan('startDate', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0), findCheapestCampervan('endDate', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0), findCheapestCampervan('id', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0), 'spaceship')}>
                        <p className="text-gray-light text-xs">{findCheapestCampervan('startDate', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0)}</p>
                        <p className="text-secondary text-base font-bold">${findCheapestCampervan('price', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0)}</p>
                      </td> :
                      <>
                        {findAlternativeLocationsCampervan('price', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate) ?
                          <td className="text-center">
                            <p>--</p>
                          </td>
                          : null}
                      </>
                    }
                  </tr>
                  {search?.pickUpLocation?.value != search?.dropOffLocation?.value && search?.dropOffLocation?.value &&
                    <tr>
                      <td className=" border-t font-normal p-2 text-left">
                        <p className="flex text-secondary">
                          {/* <img src="assets/img/front/location_on.svg" className="mr-2 size-6 shrink-0" alt="" width={24} height={24} /> */}
                          <span className="text-secondary text-base">
                            Alternate Locations
                          </span>
                        </p>
                      </td>
                      {findAlternativeLocationsCampervan('price', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value) && (campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0) != 0 ?
                        <td className="border-b-0 border-r-0 border p-3 lg:p-5 text-center hover:bg-[#FFF0D3] transition-all cursor-pointer" onClick={e => handleAlternativeLocations(search?.dropOffLocation, search?.pickUpLocation, findAlternativeLocationsCampervan('id', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0), "jucy")} >
                          <p className="text-gray-light text-xs">{findAlternativeLocationsCampervan('location', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0)}</p>
                          <p className="text-secondary text-base font-bold">${findAlternativeLocationsCampervan('price', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value || 0)}</p>
                        </td>
                        :
                        <>
                          {findAlternativeLocationsCampervan('price', 'jucy', campervans?.find(item => item?.campervan_type == 'jucy')?.Total?.Value) ?
                            <td className="text-center">
                              <p>--</p>
                            </td>
                            : null}
                        </>
                      }
                      {findAlternativeLocationsCampervan('price', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate) && (campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0) != 0 ?
                        <td className="border-b-0 border-r-0 border p-3 lg:p-5 text-center hover:bg-[#FFF0D3] transition-all cursor-pointer" onClick={e => handleAlternativeLocations(search?.dropOffLocation, search?.pickUpLocation, findAlternativeLocationsCampervan('id', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0), "spaceship")} >
                          <p className="text-gray-light text-xs">{findAlternativeLocationsCampervan('location', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0)}</p>
                          <p className="text-secondary text-base font-bold">${findAlternativeLocationsCampervan('price', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate || 0)}</p>
                        </td>
                        :
                        <>
                          {findAlternativeLocationsCampervan('price', 'defaultRate', campervans?.find(item => item?.campervan_type == 'spaceship')?.defaultRate) ?
                            <td className="text-center">
                              <p>--</p>
                            </td>
                            : null}
                        </>
                      }
                    </tr>
                  }
                </tbody>
              </table>
            </div> : null
          }
        </div>
        {campervans?.length == 0 ?
          <div className="text-center mt-4 xl:mt-10">No Records Found</div>
          : <>
            <ul className="space-y-3 mb-6 2xl:mb-10">
              {campervans && campervans?.map((item, idx) => {
                return <>
                  <li key={idx} className="bg-white rounded-2xl border border-primary flex flex-col md:flex-row gap-2 md:gap-2.5 2xl:gap-5 max-md:p-4"  
                  >
                    <div className="w-full md:max-w-[300px] relative p-2 pb-1.5 pr-0"
                     
                    >
                      <div class="relative">
                        <div className="absolute inset-0 bg-secondary/5 z-10 rounded-xl h-fit"></div>
                        {item?.campervan_type === 'jucy' || item?.Id ?
                          <ImageSliderDirect images={item?.gallery ? showJucyImages(item?.gallery, idx) : showJucyCampervanDetail(item, idx, 'gallery')} id={item?._id || item?.id || showJucyCampervanDetail(item, idx, '_id')} data={item} 
                          imageHeight={'min-h-[180px] md:h-[190px] rounded-xl'}
                          // imageHeight={`${imageHeight}px`}
                           />
                          :
                          item?.campervan_type == 'spaceship' ?
                            <ImageSliderDirect images={item?.images} id={item?._id || item?.id || showJucyCampervanDetail(item, idx, '_id')} data={item}
                             imageHeight={'min-h-[180px] md:h-[190px] rounded-xl'}
                            // imageHeight={`${imageHeight}px`}
                              />
                            :
                            <ImageSlider
                              images={item?.images?.length === 0 ? images1 : item?.images}
                              imageCounter
                              id={item?._id || item?.id || showJucyCampervanDetail(item, idx, '_id')}
                              imgHeight={190}
                              // imgHeight={imageHeight} 
                            />
                        }
                        <span className="absolute left-3 top-3">
                          <div className="w-20 h-7 rounded-lg bg-secondary  text-white flex justify-center items-center text-xs font-normal">${item?.DailyRate?.Value ? item?.DailyRate?.Value : campervanPrice(item,'Perday')}/day </div>
                        </span>
                        <span className="size-9 rounded-full bg-transparent flex items-center justify-center absolute right-2 top-1" role="button" aria-label="add-to-wishlist" onClick={e => handleFavoriteUnFavorite(item?._id || item?.id || showJucyCampervanDetail(item, idx, '_id'))}>
                          {findIsFavourite(item?.favourite_details || showJucyCampervanDetail(item, idx, 'favourite_details')) ?
                            <IoMdHeart size={24} className="text-base text-primary" />
                            :
                            <CiHeart className="size-[24px] font-medium text-base text-secondary" />
                          }
                        </span>
                      </div>
                    </div>

                    <div className="flex-1 w-full flex flex-col sm:flex-row max-md:gap-3 items-center " 
                   
                      >
                      <div className="w-full h-full flex items-center sm:max-w-[290px] lg:max-w-[370px]">
                        <div className="py-2 2xl:py-6 sm:pr-2 2xl:pr-5 w-full"
                        >
                          <h4 className="text-base 2xl:text-xl font-semibold text-secondary mb-1 "> {item?.campervan_name || item?.Name}</h4>
                          {(item?.description || item?.Description) && (
                            <p className="text-ellipsis text-sm text-gray-light mb-1.5 line-clamp-1" dangerouslySetInnerHTML={{ __html: item?.description || item?.Description }}></p>
                          )}
                          <div className={`${item?.campervan_type == 'jucy' && !item?.Total?.Value ? 'h-auto' : 'h-fit'} bg-[#F6F7FB] rounded-xl p-2 relative`}>
                            <div className="flex gap-2 justify-between items-center  ">
                              <h5 className=" flex text-xs font-normal text-gray-light whitespace-nowrap">Total Price: <span className="text-primary font-semibold ml-2">{item?.Total?.CurrencyCode || 'AUD'}${item?.defaultRate || item?.Total?.Value || 0}</span></h5>
                              <div className="App">
                                {/* <h2 data-tooltip-id="my-tooltip-1" className="cursor-pointer">
                                    <AiOutlineInfoCircle className="min-w-5 min-h-5 text-secondary" />
                                  </h2> */}
                                <button className="text-white text-[10px] bg-[#E13C29] rounded-md w-[50px] h-fit py-1 flex justify-center items-center" >Sale</button>
                              </div>

                            </div>
                            {item?.campervan_type == 'jucy' && !item?.Total?.Value || item?.campervan_type == "spaceship" && item?.defaultRate == 0 ?
                              <p className="text-xs text-secondary mt-1 line-clamp-1">To check the price please select the pickup & dropoff location and date</p>
                              : null}
                          </div>
                          <div className="flex my-1 gap-2 items-end">
                            <span className="text-xs font-normal text-gray-light">Ratings -</span>
                            <StarRatings
                              rating={4.5}
                              starSpacing="3px"
                              starRatedColor="#FFC529"
                              starHoverColor="#FFC529"
                              starDimension="17"
                              numberOfStars={5}
                              name='rating'
                            />
                            <span className="text-xs font-normal text-secondary block">(4.8)</span>
                          </div>
                          <div className="flex justify-center max-lg:flex-wrap gap-4 w-full sm:max-w-[400px] items-center">
                            <button
                              type="button"
                              onClick={e => handleAction(item?._id || item?.id || showJucyCampervanDetail(item, idx, '_id'), item, 'view details')}
                              className={`h-10 text-primary rounded-xl text-sm 2xl:text-base flex items-center justify-center border border-primary text-center hover:bg-primary hover:text-white transition-all w-full ${!checkFilters() ? 'w-full' : 'md:max-w-[187px]'}`}>
                              View Details
                            </button>
                            {checkFilters() &&
                              <button
                                type="button"
                                onClick={e => handleBookNow(item?._id || item?.id || showJucyCampervanDetail(item, idx, '_id'), item)}
                                className="w-full md:max-w-[187px] bg-primary hover:opacity-80 rounded-xl text-sm xl:text-base 2xl:text-lg text-white md:flex items-center justify-center h-10 text-center">
                                Book Now
                              </button>
                            }
                          </div>

                        </div>
                      </div>
                      {/* <div className="hidden md:block h-[300px] border-r border-gray-border" /> */}
                      {/* <div className="flex-1 flex flex-col h-full w-full gap-5 pr-4 border-l border-black"> */}
                      {/* <div className={`flex-1 flex flex-col h-full w-full gap-5 pr-4`}> */}
                      <div className={`flex-1 flex flex-col h-full w-full gap-5 ${item?.highlightedFeatures?.length > 0 || item?.Id ? 'border-l border-gray-border' : ''} py-0 min-[1400px]:px-2`}>
                        {/* {item?.Availability ?
                              <span className="shrink-0 h-[30px] w-fit lg:w-full max-lg:ml-auto justify-center p-2 rounded-full flex items-center gap-1.5 bg-[#CFFFBB] text-xs 2xl:text-sm">
                                <RxLightningBolt className="text-sm text-secondary" />{item?.Availability}
                              </span>
                              : null} */}
                        {item?.Id ?
                          <div className="grid min-[1400px]:grid grid-cols-2 sm:grid-cols-2 xl:grid-cols-2 md:flex flex-col justify-center gap-2.5 min-[1400px]:gap-6 my-auto">
                            {showJucyCampervanDetail(item, idx, 'highlightedFeatures')?.slice(0, 6)?.map(itm => {
                              return <div className="flex flex-col items-center justify-center">
                                {checkJucyAmenitiesImage(itm) && <img src={methodModel.campervanImage(checkJucyAmenitiesImage(itm))} width={30} />}
                                <p className="text-xs text-gray-light text-center capitalize">{itm}</p>
                              </div>
                            })}
                          </div>
                          :
                          <>
                            {item?.highlightedFeatures?.length === 0 || !item?.highlightedFeatures ?
                              <>
                                {item?.amenities_name === 0 || !item?.amenities_name ?
                                  <div className="text-center flex items-center justify-center h-full">
                                    <p> No Amenities</p>
                                  </div>
                                  : null
                                }
                              </> : null
                            }
                            {/* <div className="p-2 lg:p-4 grid grid-cols-2  gap-3 my-auto items-baseline"> */}
                            <div className="grid min-[1400px]:grid grid-cols-2 sm:grid-cols-2 xl:grid-cols-2 md:flex flex-col justify-center gap-2 2xl:gap-6 my-auto">

                              {item?.highlightedFeatures?.length === 0 || !item?.highlightedFeatures ?
                                <>
                                  {item?.amenities_details?.slice(0, 6)?.map(itm => {
                                    return
                                    // <div className="flex flex-col items-center justify-center ">
                                    <div className="grid min-[1400px]:grid grid-cols-2 sm:grid-cols-2 xl:grid-cols-2 md:flex flex-col justify-center gap-2 2xl:gap-6 my-auto">
                                      <img src={methodModel.campervanImage(itm?.amenities_image)} alt={itm?.image_alt_name} width={24} />
                                      <p className="text-xs text-gray-light text-center capitalize">{itm?.amenities_name}</p>
                                    </div>
                                  })}
                                </>
                                :
                                <>
                                  {item?.highlightedFeatures?.slice(0, 6)?.map(itm => {
                                    return <div className="flex flex-col items-center justify-center">
                                      {checkJucyAmenitiesImage(itm) && <img src={methodModel.campervanImage(checkJucyAmenitiesImage(itm))} width={30} />}
                                      <p className="text-xs text-gray-light text-center capitalize">{itm}</p>
                                    </div>
                                  })}
                                </>
                              }
                            </div>
                          </>
                        }
                      </div>
                    </div>
                    {/* <button
                          type="button"
                          onClick={e => handleAction(item?._id || item?.id || showJucyCampervanDetail(item, idx, '_id'), item, 'view details')}
                          className="w-full max-w-[188px] bg-primary hover:opacity-80 transition-all rounded-full text-white flex items-center justify-center h-11  text-lg text-center">
                          View Details
                        </button>
                        {checkFilters() &&
                          <button
                            type="button"
                            onClick={e => handleBookNow(item?._id || item?.id || showJucyCampervanDetail(item, idx, '_id'), item)}
                            className="w-full md:max-w-[188px] bg-primary hover:opacity-80 rounded-xl text-white  items-center text-sm justify-center h-11 mt-0.5 py-2 text-center">
                            Book Now
                          </button>
                        } */}
                  </li>
                </>
              })}
            </ul>

            <div className="mb-6 2xl:mb-10">
              {totalCampervans > filter?.count && (
                <Pagination
                  prevPageText={<IoIosArrowRoundBack className="text-xl md:text-2xl font-extralight absolute md:left-5 left-0 top-1.5 md:top-2" />}
                  nextPageText={<IoIosArrowRoundForward className="text-xl md:text-2xl font-extralight absolute md:right-6 right-0 top-1.5 md:top-2" />}
                  activePage={filter?.page}
                  itemsCountPerPage={filter?.count}
                  totalItemsCount={totalCampervans}
                  pageRangeDisplayed={7}
                  onChange={handlePagination}
                  hideFirstLastPages={true}
                />
              )}
            </div>
          </>
        }
      </div>
    </>
  )
}