
import { Tab } from '@headlessui/react'
import { Menu, Transition } from '@headlessui/react'
import moment from 'moment'
import { Fragment, useEffect, useState } from 'react'
import ApiClient from '../../methods/api/apiClient'
import methodModel from '../../methods/methods'
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { GoArrowRight } from "react-icons/go";

const BookingConfirmation = ({ form, search, campervanDetails, extraItems, selectedInsurance, findTotalAddOns }) => {
  const [suggestedItineraries, setSuggestedItineraries] = useState([])
  const [showItineraries, setShowItineraries] = useState(6)
  const [Cities, setCities] = useState([])
  const [selectedItineries, setselectedItineries] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    let arr = []
    if (search?.dropOffLocation?.label) {
      if (search?.pickUpLocation?.label == search?.dropOffLocation?.label) {
        arr = String(search?.pickUpLocation?.label)
      }
      else {
        arr = String([search?.pickUpLocation?.label, search?.dropOffLocation?.label])
      }
    }
    else {
      arr = String(search?.pickUpLocation?.label)
    }
    getSuggestedItineraries(arr)
    getCities()
  }, [])

  const getCities = () => {
    ApiClient.get(`cities?status=active&isPopulated=true`).then(res => {
      if (res.success) {
        setCities(res?.data?.map((item) => {
          return ({ ...item, value: item?.id || item?._id, label: item?.city_name })
        }))
      }
    })
  }

  const getSuggestedItineraries = (p) => {
    setselectedItineries(p)
    let payload = { status: 'active', isSuggested: 'true', city: p }
    ApiClient.get(`Itinerariers/all`, payload).then(res => {
      if (res.success) {
        setSuggestedItineraries(res?.data)
      }
    })
  }

  return (
    <>
      <div className="max-w-[1264px] w-full mx-auto px-4 lg:px-8 mt-6 2xl:mt-12">
        
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2  gap-x-3  2xl:gap-x-6  gap-y-6 lg:gap-y-0 mb-6 2xl:mb-12 ">
          <div className="">
          <div className="flex items-center gap-x-3">
          {/* <img src="/assets/img/front/checked.svg" className="size-12 shrink-0" alt='checked' /> */}
          <div className='bg-primary w-7 h-7 md:w-8 md:h-8 xl:w-10 xl:h-10 rounded-full flex justify-center items-center' > <FaCheck className="text-[#ffffff] w-3 h-3 lg:w-4  lg:h-4" />  </div>

          <p className="font-semibold text-secondary text-base lg:text-xl 2xl:text-2xl">Bond Details</p>
        </div>
            <div className="rounded-xl bg-[#EEFBF4] mt-3 p-4 2xl:p-8">
              <p className='text-sm font-normal text-gray-light'>Thank you for your booking. You will receive an email with your booking details and confirmation. Please review your booking and contact us if you need further information.</p>
            </div>
              <h6 className="mt-3 font-semibold text-secondary text-base lg:text-xl 2xl:text-2xl">Your booking details</h6>
              <div className="mt-3 border border-primary p-4 2xl:p-8 rounded-[20px]">
                <p className="font-semibold text-secondary text-base lg:text-lg  2xl:text-xl">Booking Details :</p>
                <div className="pt-4">
                  <div className="titeld flex gap-3 sm:items-center justify-between max-sm:flex-wrap">
                    <div className="w-1/2">
                      <h6 className="text-secondary text-lg/6 mb-1.5 lg:mb-3">Pick Up</h6>
                      <p className="text-gray-light text-base">{search?.pickUpLocation?.label || '--'} - {moment(form?.pickUpDate?.startDate).format('DD MMM YYYY')}</p>
                    </div>
                    <div className="w-1/2">
                      <h6 className="text-secondary text-lg/6 mb-1.5 lg:mb-3">Drop Off</h6>
                      <p className="text-gray-light text-base">{search?.dropOffLocation?.label || search?.pickUpLocation?.label || '--'} - {moment(form?.dropOffDate?.startDate).format('DD MMM YYYY')}</p>
                    </div>
                  </div>
                </div>
                  <div className="mt-4 titeld flex gap-3 sm:items-center justify-between max-sm:flex-wrap">
                    <div className="w-1/2">
                      <h6 className="text-secondary text-lg/6 mb-1.5 lg:mb-3">Pick Up Time</h6>
                      <p className="text-gray-light text-base">{moment(form?.pickUpDate?.startDate).format('ddd, DD MMM YYYY')} {form?.pickUpTime?.value ? `- ${form?.pickUpTime?.value}` : ""}</p>
                    </div>
                    <div className="w-1/2">
                      <h6 className="text-secondary text-lg/6">Pick Up Time</h6>
                      <p className="text-gray-light text-base">{moment(form?.dropOffDate?.startDate).format('ddd, DD MMM YYYY')} {form?.dropOffTime?.value ? `- ${form?.dropOffTime?.value}` : ""}</p>
                    </div>
                  </div>
                {campervanDetails?.campervan_type != "spaceship"?
                <div className="mt-4">
                  <div className="border-t border-gray-border pt-4">
                    <p className="font-semibold text-secondary text-lg lg:text-xl">Insurance Details :</p>
                  </div>
                  <div className="included_text mt-3">
                    <p>Included:</p>
                    <ul className="list-disc list-outside marker:text-primary marker:text-xl/none pl-5 mt-3 flex flex-col gap-2.5 lg:gap-y-5">
                      <li><p className="text-gray-light text-base">Minimum Liability Limits</p></li>
                      <li><p className="text-gray-light text-base">Physical Damage (300K)</p></li>
                      <li><p className="text-gray-light text-base">Roadside Assistance</p></li>
                      <li><p className="text-gray-light text-base">Windshield Coverage</p></li>
                      <li><p className="text-gray-light text-base">24/7 Customer Support</p></li>
                    </ul>
                  </div>
                </div>
                :null}
                {findTotalAddOns() != 0 ?
                  <div className="mt-4">
                    <div className="border-t border-gray-border pt-4">
                      <p className="font-semibold text-secondary text-lg lg:text-xl">Add On Details :</p>
                    </div>
                    <div className="">
                      <div className=" mt-6 flex flex-col gap-y-2.5 lg:gap-y-5">
                        {extraItems && extraItems?.map((item, index) => {
                          return <>
                            {item?.Items?.map((itm, i) => {
                              return <>
                                {itm?.Qty > 0 ?
                                  <div key={i} className="flex justify-between items-center">
                                    <p className="text-gray-light text-base">{itm?.Name}</p>
                                    <p className="text-gray-light text-base">${itm?.Total?.Value}</p>
                                  </div>
                                  : null}
                              </>
                            })}
                          </>
                        })}
                      </div>
                    </div>
                  </div>
                  : null}
                <div className="mt-4">
                  <div className="border-t border-gray-border pt-4">
                    <p className="font-semibold text-secondary  text-base lg:text-lg  xl:text-xl">Cost Details :</p>
                  </div>
                    <div className="mt-3 lg:mt-6 flex flex-col gap-2.5 lg:gap-5">
                      <div className="flex justify-between items-center">
                        <p className="text-gray-light text-base">Rental {form?.total_days || 0}x Days</p>
                        <p className="text-[#2b2b2b] font-medium text-base">${form?.campervanPrice || 0}</p>
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="text-gray-light text-base">Taxes and fees</p>
                        <p className="text-[#2b2b2b] font-medium text-base">${parseFloat(form?.taxesAndFees || 0).toFixed(2)}</p>
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="text-gray-light text-base">Insurance {selectedInsurance?.ExcessType ? <span className='bg-primary text-white rounded-full text-sm py-1 px-4'>{selectedInsurance?.ExcessType}</span> : null}</p>
                        <p className="text-[#2b2b2b] font-medium text-base">${selectedInsurance?.Total?.Value || 0}</p>
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="text-gray-light text-base">Add Ons</p>
                        <p className="text-[#2b2b2b] font-medium text-base">${findTotalAddOns()}</p>
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="text-gray-light text-base">Security Deposit (refundable)</p>
                        <p className="text-[#2b2b2b] font-medium text-base">${form?.securityDeposit || 0}</p>
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="text-gray-light text-base">Bond Details</p>
                        <p className="text-[#2b2b2b] font-medium text-base">${form?.bondDetails || 0}</p>
                      </div>
                    </div>
                </div>
                <div className="mt-4">
                  <div className="border-t border-gray-border pt-4 flex items-center justify-between">
                    <p className="font-medium text-gray-light lg:text-lg ">Total</p>
                    <p className="font-semibold text-secondary text-lg lg:text-2xl">${form?.campervan_price || 0} </p>
                  </div>
                </div>
              </div>
            {(campervanDetails?.cancellation_policy && campervanDetails?.cancellation_policy_name) ?
              <>
              <h6 className="text-xl lg:text-2xl font-semibold text-secondary mt-5">Cancellation Policy</h6>
              <div className="rounded-xl bg-[#EEFBF4] mt-3 p-4 lg:p-8">
                
                <p className='text-sm font-normal text-gray-light'>There are currently no terms and conditions for travel during this period. If your rental is during these travel dates, new terms and conditions may apply. Please contact us before booking.</p>
              </div>
                <div className="mt-5 overflow-auto rounded-lg border border-gray-border">
                  <table className="w-full">
                    <thead>
                      <tr className="h-[55px] border-b border-gray-border">
                        <th className="text-left font-medium border-r  w-1/2 border-gray-border px-4 mb-3 text-secondary text-base  lg:text-lg">Cancellation Period</th>
                        <th className="text-left font-medium w-1/2 px-4 mb-3 text-secondary text-base lg:text-lg">Refund Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {campervanDetails?.cancellation_policy?.map((itm) =>
                      (
                        <tr className="h-[55px] border-b border-gray-border">
                          <td className="font-normal border-r border-gray-border px-4 mb-3 text-gray-light text-base  ">{itm?.days_hours_travel_date + " " + itm?.days_hours?.value} prior to pickup</td>
                          <td className="font-normal px-4 mb-3 text-gray-light text-base ">{itm?.per_amount?.value == "percentage" ? itm?.refund + "%" : "$" + itm?.refund}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
            </>
              :
              null
            }
          </div>
          <div className="suggested_data">
            <div className="flex items-center gap-x-4">
              <p className="font-semibold text-secondary text-lg 2xl:text-2xl">Suggested Itineraries</p>
            </div>
            <div className="tabs_list">
              <div className="w-full px-2 pt-6 sm:px-0">
                <Tab.Group>
                  <Tab.List>
                    <div className='flex items-center gap-x-2 gap-y-2 flex-wrap mb-4'>
                      {Cities && Cities?.map((itm, index) =>
                      (
                        <>
                          {
                            index < 3 ? <Tab className={`p-2.5 rounded-xl font-normal border border-gray-border text-base/5 ${selectedItineries == itm?.city_name ? 'bg-primary text-white' : 'bg-transparent text-secondary'}`} onClick={e => getSuggestedItineraries(itm?.city_name)}>{itm?.city_name}</Tab> : null
                          }
                        </>
                      ))}
                      <div className='ml-auto'>
                        <Menu as="div" className="relative w-full ">
                          <div>
                            <Menu.Button className="flex justify-between items-center bg-white p-2.5 mr-2 border border-gray-border rounded-xl font-normal text-gray-light text-sm cursor-pointer">
                              All States
                              {/* <IoMdArrowDropdown
                                className="-mr-1 ml-1 h-4 w-4 text-black "
                                aria-hidden="true"
                              /> */}
                                <MdKeyboardArrowDown
                                  className={`ml-2 block size-5 text-secondary `}
                                />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                              <div className="px-1 py-1 ">
                                {
                                  Cities && Cities?.map((itm) =>
                                  (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          className={`${active ? 'bg-blue-800 ' : ''
                                            } ${selectedItineries == itm?.city_name ? 'bg-primary text-[#fff]' : 'bg-transparent text-black'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                          onClick={e => getSuggestedItineraries(itm?.city_name)}
                                        >
                                          {itm?.city_name}
                                        </button>
                                      )}
                                    </Menu.Item>
                                  )
                                  )
                                }
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </Tab.List>
                  {
                    suggestedItineraries?.length > 0 ?
                      <Tab.Panels>
                        {[...Array(3)].map((_itm, idx) => (
                          <Tab.Panel key={idx}>
                            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-x-4 gap-y-4'>
                              {suggestedItineraries && suggestedItineraries?.slice(0, showItineraries)?.map((item, index) => (
                                <div key={index} className='tabs_showing'>
                                  <div className='border border-gray-border p-2 pb-4 bg-white rounded-2xl'>
                                      <img src={item?.image ? methodModel.noImg(item?.image) : "/assets/img/tabs.png"} className='w-full rounded-lg h-[238px] object-cover' alt='tabs.png' />
                                    <div className='mt-4'>
                                      <h6 className='font-medium text-secondary text-base mb-3 lg:text-lg'>{item?.title || '--'}</h6>
                                      <p className='text-sm lg:text-base font-normal text-gray-light line-clamp-3 mb-3 lg:mb-6' dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                      <button className="rounded-xl w-full p-2 hover:text-white border border-primary hover:bg-primary transition-all text-sm lg:text-lg text-primary" onClick={e => { navigate(`/itineraries/${item?._id || item?.id}`) }}>View Details</button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {suggestedItineraries?.length > 6 &&
                              <div className="pt-5 lg:pt-10">
                                <button onClick={e => setShowItineraries(showItineraries == 6 ? 1000 : 6)} className="mx-auto flex gap-2 items-center px-10 py-3.5 border border-primary text-base rounded-xl bg-primary text-[#F6F7FB] transition-all hover:bg-opacity-80">Show {showItineraries == 6 ? "Less" : "All"} 
                                  <GoArrowRight className="text-white size-5" />
                                </button>
                              </div>}
                          </Tab.Panel>
                        ))}
                      </Tab.Panels>
                      : <p className='text-center'>
                        No Suggested Itinerariers
                      </p>
                  }
                </Tab.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BookingConfirmation