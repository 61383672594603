import { useSelector } from "react-redux"

const PickupLocation = ({ detail }) => {
  const siteSettings = useSelector((state) => state.Reducer.siteSettings)
  const search = useSelector((state) => state.Reducer.search)

  return (
    <>
      <div className="max-sm:col-span-1 max-xl:col-span-2 icons_ilustion  rounded-2xl border-[0.75px]  border-[#6078C6] p-3 lg:p-4 2xl:p-6">
        <div className="flex flex-col">
          <h2 className="font-semibold text-secondary text-base 2xl:text-lg/5">Pickup Location</h2>
            <div className="mt-2 lg:mt-4 flex flex-col items-center">
              <div className="h-[300px] w-full">
                <iframe
                  src={`https://www.google.com/maps/embed/v1/place?key=${siteSettings?.google_map_api_key}&q=${detail?.pickUpLocation ? detail?.pickUpLocation : search?.pickUpLocation?.label ? search?.pickUpLocation?.label : '20 Noble Avenue, Brisbane , Australia'}`}
                  className="ifrmae_img"
                  height="300px"
                  width={"100%"}
                  style={{ borderRadius: '8px 0px 0px 8px' }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Map"
                ></iframe>
              </div>
              <div className="mt-4">
                  <h6 className="font-medium text-secondary text-base">Brisbane Depot</h6>
                  <div className="mt-2">
                    <h6 className="font-medium text-secondary text-sm mb-1">Address :</h6>
                    <p className="text-gray-light text-sm">{detail?.pickUpLocation ? detail?.pickUpLocation : search?.pickUpLocation?.label ? search?.pickUpLocation?.label : '20 Noble Avenue, Brisbane , Australia'}</p>
                  </div>
                  <div className="mt-3">
                    <h6 className="font-medium text-secondary text-sm mb-1">Pick Up Instructions:</h6>
                    <p className="text-[#8A8A8A] text-sm font-normal">Please make your own way to the branch The branch is located 16 kilometres from the city.The branch is located 9 kilometres from the airport. A taxi fare is approximately $45 from the city. </p>
                  </div>
                  <div className="mt-3">
                    <h6 className="text-secondary font-medium text-sm">Depot Parking Info: <span className="text-gray-light text-sm font-normal">Inside parking $6 a day or street parking.</span></h6>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default PickupLocation