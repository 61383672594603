import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FiMinus } from "react-icons/fi";

interface CampervanCategoryProps {
  tab: number;
  settab: React.Dispatch<React.SetStateAction<number>>;
}

const InsuranceText = [
  { label: "Number of seats with seatbelt" },
  { label: "Number of people who can sleep in the vehicle" },
  { label: "Number of beds" },
];

const CampervanCategory: React.FC<CampervanCategoryProps> = ({ settab }) => {
  const [activeIdx, setActiveIdx] = useState(0);
  const handleSleepCount = (idx: number) => {
    setActiveIdx(idx);
  };

  const [values, setValues] = useState<number[]>([1, 1, 1]);

  const increment = (index: number) => {
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = newValues[index] + 1;
      return newValues;
    });
  };
  const decrement = (index: number) => {
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = Math.max(newValues[index] - 1, 0);
      return newValues;
    });
  };

  return (
    <div className={`max-w-[1264px] w-full mx-auto px-3 lg:px-8 mt-6 2xl:mt-12`}>
      <h3 className="text-xl 2xl:text-2xl/9 font-semibold text-secondary">
        What type of RV do you have?
      </h3>
      <div className="flex gap-3 flex-wrap mt-4 2xl:mt-8 justify-center sm:justify-start">
        {[...Array(5)].map((_itm, idx) => (
          <div
            onClick={() => handleSleepCount(idx)}
            key={idx}
            className={`w-[150px] cursor-pointer h-[50px] flex items-center px-4 border rounded-xl ${activeIdx === idx ? "border-primary" : "border-[#CDCDCD]"
              } `}
          >
            {/* <input
              type="radio"
              className="size-6 mr-3 rounded-full block accent-[#084ead] "
              checked={activeIdx === idx}
            /> */}
            <label className="radio-container">
              <input
                type="radio"
                className="hidden"
                checked={activeIdx === idx}
              />
              <span className="custom-radio"></span>
            </label>
            <span className="text-sm lg:text-base leading-[22.4px] font-medium text-secondary">
              Sleeps {idx + 2}{" "}
            </span>
          </div>
        ))}
      </div>
      <div className="max-w-[1264px] w-full mt-6 2xl:mt-12">
        <h3 className="text-xl 2xl:text-2xl font-semibold text-secondary mb-4">
          Accommodation
        </h3>
        {values.map((value, index) => (
          <div
            key={index}
            className="flex items-center justify-between border border-[#CDCDCD] mb-3 px-4 py-[18px] w-full rounded-lg"
          >
            <p className="text-sm/5 lg:text-base/5 font-normal text-secondary">
              {InsuranceText[index]?.label}
            </p>
            <div className="flex gap-2 md:gap-4 items-center">
              <button
                type="button"
                onClick={() => decrement(index)}
                className="flex justify-center items-center border border-gray-border size-6 md:size-8 rounded-full"
              >
                <FiMinus className="size-4 text-gray-light" />
              </button>
              <input
                type="text"
                className="text-base/5 font-normal text-secondary w-3 text-center"
                value={value}
                readOnly
              />
              <button
                type="button"
                onClick={() => increment(index)}
                className="flex justify-center items-center border border-gray-border size-6 md:size-8 rounded-full"
              >
                <FaPlus className="size-4 text-secondary" />
              </button>
            </div>
          </div>
        ))}
        <h3 className="text-xl 2xl:text-2xl font-semibold text-secondary my-3 2xl:my-6">
          Insurance Information
        </h3>
        <div className="max-w-[1006px] flex gap-2 items-center w-full border border-[#CDCDCD] p-4 sm:h-[60px] rounded-lg mb-6">
          <input
            type="checkbox"
            id="insurance"
            className="shrink-0 text-primary size-4 border border-gray-400 rounded-sm cursor-pointer appearance-none"
          />
          <label htmlFor="insurance" className="cursor-pointer block text-base/5 font-normal text-secondary">
            If you are a campervan or RV renter company, please tick this box.
          </label>
        </div>
      </div>
      <button
        type="button"
        onClick={() => settab(2)}
        className="text-sm  block h-[60px] w-[205px] text-white bg-primary rounded-lg hover:opacity-80"
      >
        Save & continue
      </button>
    </div>
  );
};

export default CampervanCategory;
