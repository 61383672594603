import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ApiClient from "../../methods/api/apiClient";
import InputSelect from "../../components/common/InputSelect";

interface ListingPricingProps {
  tab: number;
  settab: React.Dispatch<React.SetStateAction<number>>;
}

const PolicyText = [
  { label: "Super Flexible Policy" },
  { label: "Flexible Policy" },
  { label: "Moderate Policy" },
  { label: "Strict Policy" },
];

const ListingPricing: React.FC<ListingPricingProps> = ({ settab }) => {
  const [form, setForm] = useState({ email: "" });
  const [emailSent, setemailSent] = useState(false);

  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const handleSelectionChange = (idx: number) => {
    setSelectedItem(idx);
  };

  const FormSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = (value: any) => {
    let payload: any = {
      email: value.email,
    };
    ApiClient.post("", payload).then((res) => {
      if (res.success) {
        setForm(value);
        setemailSent(true);
      } else {
        setemailSent(false);
      }
    });
  };

  const assistance = [
    { name: "Assistance 1" },
    { name: "Assistance 2" },
    { name: "Assistance 3" },
  ];
  const [selectedAssistance, setSelectedAssistance] = useState(assistance[0]);

  return (
    <div className={`max-w-[1264px] w-full mx-auto px-4 lg:px-8 mt-6 2xl:mt-12`}>
      <Formik
        initialValues={form}
        validationSchema={FormSchema}
        onSubmit={handleSubmit}
      >
        <Form className="">
          <div className="w-full mb-3">
            <label className= "text-sm 2xl:text-lg/7 text-secondary font-semibold block mb-3">
              Price per night
            </label>
            <div className="flex mb-2">
              <Field
                type="text"
                name="night"
                className="resize-none  focus:ring-offset-[1.2px] ring-1 ring-[#CDCDCD] focus:ring-primary outline-none rounded-lg !border-0   w-full !h-[47px] p-4 block appearance-none"
              />
              {/* <span className="whitespace-nowrap px-6 py-[18px] -my-[.9px] rounded-r-lg bg-[#E6E6E6] text-base font-normal text-secondary">
                $ per night
              </span> */}
            </div>
            <span className="text-base font-normal text-secondary/70">
              Number only
            </span>
          </div>
          <div className="mb-4 w-full sm:max-w-[calc(50%_-_12px)]">
            <label className= "text-sm 2xl:text-lg/7 text-secondary font-semibold block mb-3">
              Minimum night
            </label>
            <div className="flex mb-2">
              <Field
                type="text"
                name="minimum"
                className="resize-none focus:ring-offset-[1.2px] ring-1 ring-[#CDCDCD] focus:ring-primary outline-none rounded-lg !border-0   appearance-none   w-full !h-[47px] p-4 block "
              />
              {/* <span className="px-6 py-[18px] -my-[.9px] rounded-r-lg bg-[#E6E6E6] text-base font-normal text-secondary">
                Night
              </span> */}
            </div>
            <div className="w-full">
              <label className="text-sm text-secondary font-medium block mb-2">
                Minimum Night (Number Only)
              </label>
              <div className="flex mb-2">
                <Field
                  type="text"
                  name="minimum"
                  placeholder="$ per night"
                  className="resize-none focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 rounded-l-xl   appearance-none   w-full h-11 px-4 py-3 block "
                />
                
              </div>
              {/* <span className="text-base font-normal text-secondary/70">
              Number only
            </span>
          </div>
          <div className="flex gap-3 mb-2 flex-col sm:flex-row">
            <div className="w-full">
              <label className= "text-sm 2xl:text-lg/7 text-secondary font-semibold block mb-3">
                Security deposit
              </label>
              <div className="flex mb-2">
                <Field
                  type="text"
                  name="deposit"
                  className="resize-none focus:ring-offset-[1.2px] ring-1 ring-[#CDCDCD] focus:ring-primary outline-none rounded-lg !border-0   w-full !h-[47px] p-4 block appearance-none"
                />
                {/* <span className="whitespace-nowrap px-6 py-[18px] -my-[.9px] rounded-r-lg bg-[#E6E6E6] text-base font-normal text-secondary">
                  $-Dollar
                </span> */}
              </div>
              {/* <span className="text-base font-normal text-secondary/70">
                Number only
              </span> */}
            </div>{" "}
            <div className="w-full">
              <label className= "text-sm 2xl:text-lg/7 text-secondary font-semibold block mb-3">
                Deductible
              </label>
              <div className="flex mb-2">
                <Field
                  type="text"
                  name="deduct"
                  className="resize-none focus:ring-offset-[1.2px] ring-1 ring-[#CDCDCD] focus:ring-primary outline-none rounded-lg !border-0   w-full !h-[47px] p-4 block appearance-none"
                />
            </div>
            <div className="w-full">
              <label
                htmlFor=""
                className="text-sm text-secondary font-medium block mb-2"
              >
                Roadside Assistance
              </label>
              <InputSelect
                classes={
                  "w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 "
                }
                labelBg={"bg-white"}
                options={assistance}
                value={selectedAssistance}
                onChange={setSelectedAssistance}
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-3 mt-6 2xl:mt-12">
            <div className="w-full">
              <h5 className="text-base/5 font-medium text-secondary mb-5">
                Cancellation Policy
              </h5>
              <div className="flex flex-col gap-4">
                {[...Array(4)].map((_itm, idx) => (
                  <div
                    onClick={() => handleSelectionChange(idx)}
                    key={idx}
                    className={`w-full cursor-pointer !h-[47px] flex items-center px-4 border rounded-xl ${selectedItem === idx
                      ? "border-primary"
                      : "border-[#CDCDCD]"
                      } `}
                  >
                    <label className="radio-container">
                      <input
                        type="radio"
                        className="hidden"
                        checked={selectedItem === idx}
                        onChange={() => {}}
                      />
                      <span className="custom-radio-btn"></span>
                    </label>
                    <div key={idx}>
                      <p
                        className={`text-sm font-normal ${
                          selectedItem === idx
                            ? "text-primary"
                            : "text-gray-light"
                        }`}
                      >
                        {PolicyText[idx]?.label}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full">
              <h5 className="text-base/5 font-semibold text-secondary mb-4 lg:mb-7 ">
                Super Flexible Policy
              </h5>
              <p className="text-sm font-normal text-gray-light mb-4">
                Lorem ipsum dolor sit amet consectetur. Sit aliquam neque tortor
                urna ultrices suspendisse risus purus est. Dictum mauris augue
                tempor mattis ut vulputate at rhoncus et. Enim maecenas elit
                dignissim turpis purus scelerisque placerat. Habitasse velit
                amet quis amet aenean cursus eget vitae.{" "}
              </p>
              <p className="text-sm font-normal text-gray-light mb-4">
                Eu nullam suspendisse egestas vitae vel diam. Et facilisi
                egestas lacus ipsum magnis consectetur rhoncus gravida egestas.
                Pellentesque morbi aliquet tellus tortor diam in semper lacus.
                Lorem dolor sapien pulvinar posuere sit laoreet dictum
                condimentum.{" "}
              </p>
              <p className="text-sm font-normal text-gray-light mb-4">
                Neque est a ut quis nisi consectetur. Quis ultricies neque
                sapien consequat molestie nisi nibh. Viverra venenatis tincidunt
                facilisi donec eu sed euismod. Elementum arcu volutpat vitae
                faucibus sem tortor lectus mauris. Euismod in elementum amet
                tortor ac aliquet. Faucibus sed maecenas sem sagittis neque
                mattis.
              </p>
            </div>
          </div>
        </Form>
      </Formik>

      <div className="flex flex-col sm:flex-row items-center gap-3 mt-8 sm:gap-5 mb-12">
        <button
          type="button"
          onClick={() => settab(7)}
          className="block !h-[47px] w-[205px] text-white bg-primary rounded-lg hover:opacity-80"
        >
          Save & continue
        </button>
        <Link
          to=""
          onClick={() => settab(7)}
          className="text-sm lg:text-base 2xl:text-lg/5 font-medium text-gray-light flex items-center justify-center h-10 xl:h-12 2xl:h-[54px] w-full sm:max-w-[152px] rounded-xl border border-gray-border hover:bg-primary hover:text-[#F6F7FB] transition-all"
        >
          Skip Setup
        </Link>
      </div>
    </div>
  );
};

export default ListingPricing;
